import { MediaItem } from 'Shared/FrameModules/MediaLibrary/item';
import { MediaTarget } from 'Shared/FrameModules/MediaLibrary/target';
import { FrameModules } from 'Shared/frame_modules';
import { MediaItemImageDisplay } from 'Shared/FrameModules/MediaLibrary/item_image';

FrameModules.add('WikiImageFrameModule', function () {
   when($('pageImages'), function (imageThumbs) {
      WikiImageManager.initialize();

      when($('wikiSummaryImage'), function (elementId) {
         let targetElement = $(elementId);
         if (!targetElement) {
            return;
         }

         let target = MediaTarget.createFromElement(targetElement);
         target.addEvent('itemChanged', function (mediaItem, old) {
            if (!mediaItem) {
               return;
            }
            WikiImageManager.addImage(mediaItem, old);
         });
      });
   });
});

// eslint-disable-next-line no-var
export var WikiImageManager = (window.WikiImageManager = {
   initialize: function () {
      let self = this;
      this.thumbsContainer = $('pageImages');
      this.imageMeta = $$('.imageMeta');

      // Get image data for each of the images attached to this wiki, and then
      let wikiid;
      if ((wikiid = $('wikiid').get('text'))) {
         new Request.AjaxIO('getWikiImages', {
            onSuccess: function (images) {
               images.map(function (itemData) {
                  let mediaItem = MediaItem.createFromData(itemData);
                  self.addImage(mediaItem);
               });
            },
         }).send(wikiid);
      }
   },

   addImage: function (mediaItem, toReplace) {
      if (toReplace) {
         this.removeImage(toReplace);
      }

      let thumb = new MediaItemImageDisplay(mediaItem, {
         size: 'thumbnail',
         useBackground: false,
         showFilename: false,
         menu: false,
      });
      let thumbElement = thumb.getElement();
      this.thumbsContainer.grab(thumbElement);

      let hideEvent,
         text = this.imageMeta.getFirst();

      // Show the imageid for 5 seconds on hover
      thumbElement.addEvent('mouseenter', function (ev) {
         clearTimeout(hideEvent);
         text.set('text', mediaItem.getGlobalID());
         hideEvent = text.makeInvisible.delay(5000, text);
      });

      this.imageMeta.addEvents({
         mouseenter: function (ev) {
            clearTimeout(hideEvent);
            text.makeVisible();
         },
         mouseleave: function (ev) {
            hideEvent = text.makeInvisible.delay(5000, text);
         },
      });
   },

   removeImage: function (mediaItem) {
      return $$('#pageImages .mediaItem[data-imageid="' + mediaItem.getID() + '"]').dispose();
   },
});
