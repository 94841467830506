const oscaroAffiliateCodes = {
   utm_source: 'iFixit',
   utm_medium: 'repairguides',
   utm_campaign: 'partlinks',
};

const createLinksModifier = affiliateConfig => ({
   'amazon.com': link => {
      const url = new URL(link);
      url.searchParams.set('tag', affiliateConfig['amazon.com']);
      return url.toString();
   },
   'amzn.com': link => {
      const url = new URL(link);
      url.searchParams.set('tag', affiliateConfig['amzn.com']);
      return url.toString();
   },
   'ebay.com': link => {
      const url = new URL(affiliateConfig['ebay.com']);
      url.searchParams.set('mpre', link);
      return url.toString();
   },
   'oscaroparts.com': link => {
      const url = new URL(link);
      Object.keys(oscaroAffiliateCodes).forEach(codeName => {
         url.searchParams.set(codeName, oscaroAffiliateCodes[codeName]);
      });
      return url.toString();
   },
   'newegg.com': link => affiliateConfig['newegg.com'] + link,
   'etradesupply.com': link => {
      const url = new URL(link);
      url.searchParams.set('acc', affiliateConfig['etradesupply.com']);
      return url.toString();
   },
});

export default createLinksModifier;
