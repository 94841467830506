import { FrameModules } from 'Shared/frame_modules';

FrameModules.add('WikiHelpSidebarFrameModule', function () {
   let sidebar = $('wikiHelpSidebar'),
      sectionSelector = '.subSection ul',
      displaySection;

   let linkSelector = "a[href$='" + window.location.pathname + "']",
      linkToThisPage = sidebar.getElements(linkSelector)[0];

   if (linkToThisPage) {
      displaySection = linkToThisPage.getParent(sectionSelector);
   }

   let accordion = new Fx.Accordion(sidebar, '.subSection', sectionSelector, {
      duration: 250,
      display: displaySection,
      initialDisplayFx: false,
      onActive: function (toggler) {
         toggler.addClass('highlight');
      },
      onBackground: function (toggler) {
         toggler.removeClass('highlight');
      },
   });
});
