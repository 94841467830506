 

function getter(json) {
   return function(key) {
      if (!json.hasOwnProperty(key)) {
         var errorStr = 'Property ' + key + ' does not exist!';
         throw errorStr;
      }
      return json[key];
   };
}

window.guide_constants = {
   ApprovalProcess: getter({
      STATUS_ENABLED: 'enabled',
      STATUS_DISABLED: 'disabled'
   }),

   ApprovalRequest: getter({
      DATETIME_FORMAT: '%h %e, %Y @ %l:%M:%S%P',
      STATUS_APPROVED: 'approved',
      STATUS_REJECTED: 'rejected',
      STATUS_CANCELLED: 'cancelled'
   }),

   ApprovalRequestStageSignature: getter({
      STATUS_APPROVE: 'approve',
      STATUS_REJECT: 'reject'
   }),

   DocumentWatch: getter({
      DOCTYPE_GUIDE: 'guide',
      LEVEL_EDIT: 'edit'
   }),

   fa: getter({
      ANGLE_DOWN: '<i class="fa fa-angle-down " ></i>',
      ANGLE_LEFT: '<i class="fa fa-angle-left " ></i> ',
      ANGLE_RIGHT: '<i class="fa fa-angle-right " ></i>',
      ANGLE_UP: '<i class="fa fa-angle-up " ></i>',
      BOLT: '<i class="fa fa-bolt " ></i>',
      CHECK: '<i class="fa fa-check " ></i>',
      CHECK_CIRCLE: '<i class="fa fa-check-circle " ></i>',
      EXCLAMATION_CIRCLE: '<i class="fa fa-exclamation-circle " ></i>',
      FILES_O: '<i class="fa fa-files-o " ></i>',
      INFO_CIRCLE: '<i class="fa fa-info-circle " ></i>',
      PENCIL: '<i class="fa fa-pencil " ></i>',
      SEARCH_PLUS: '<i class="fa fa-search-plus " ></i>',
      TIMES: '<i class="fa fa-times " ></i>'
   }),

   GuideURI: getter({
      ADMIN_SUPPORT_LINK: '<a href="/Manage/support">',
      APPROVALPROCESSVIEW_ZZZZZ: '/Manage/approvals/zzzzz',
      APPROVALREQUESTVIEW_ZZZZZ: '/User/approvals/zzzzz',
      TAG_ADMIN_ANNOUNCEMENTS: '/Manage/announcements',
      TAG_COLLECTIONS_EDIT: '/Manage/Collections/Edit/',
      TAG_GUIDE_CREATE: '/Guide/new',
      TAG_GUIDE_HISTORY: '/Guide/history',
      TAG_FORMATTING_HELP: '/Help/Wiki_Formatting_And_Syntax',
      TAG_REMOTE_LOGIN: '/Guide/User/remote_login',
      VIEWGUIDENOQUERY: '/Guide/guide/zzzzz',
      USER_REVIEWS_LINK: '/User/Reviews',
      TAG_PERIODIC_REVIEW: '/User/periodic_review'
   }),

   Release: getter({
      STATUS_PENDING: 'pending',
      STATUS_RELEASED: 'released',
      MAX_TITLE_LENGTH: '48'
   }),

   SearchClickthroughs: getter({
      COOKIE_NAME: 'searchClickthrough',
      COOKIE_TEMPLATE: '{id},{type},{index}'
   }),

   SpamReview: getter({
      VALUE_SPAM: 'spam',
      VALUE_HAM: 'ham'
   }),

   SupervisorSignoff: getter({
      DATETIME_FORMAT: '%A, %B %e %Y %H:%M %p'
   }),

   UserLib: getter({
      ERR_PASSWORD_MISMATCH: 'password_mismatch',
      ERR_PASSWORD_INVALID: 'password_invalid',
      ERR_USERNAME_INVALID: 'invalid_username',
      ERR_UNIQUE_NAME_INVALID: 'invalid_unique_name',
      ERR_EMAIL_INVALID: 'invalid_email',
      ERR_EMAIL_EXISTS: 'login_exists',
      ERR_DISALLOWED: 'disallowed',
      ERR_USER_LIMIT: 'user_limit',
      ERR_UNKNOWN: 'default',
      ERR_CAPTCHA_FAIL: 'captcha_fail'
   }),
}
