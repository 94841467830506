// This refactor alows dragable objects to work on all media devices.
// Once this is properly tested we should probably globally override Drag.
Class.refactor(Drag, {
   attach: function () {
      this.handles.addEvent('touchstart', this.bound.start);
      return this.previous.apply(this, arguments);
   },

   detach: function () {
      this.handles.removeEvent('touchstart', this.bound.start);
      return this.previous.apply(this, arguments);
   },

   start: function (event) {
      event.preventDefault();
      document.body.addEvents({
         touchmove: this.bound.check,
         touchend: this.bound.cancel,
      });
      this.previous.apply(this, arguments);
   },

   check: function (event) {
      event.preventDefault();
      if (this.options.preventDefault) {
         event.preventDefault();
      }
      let distance = Math.round(
         Math.sqrt(
            Math.pow(event.page.x - this.mouse.start.x, 2) +
               Math.pow(event.page.y - this.mouse.start.y, 2)
         )
      );
      if (distance > this.options.snap) {
         this.cancel();
         this.document.addEvents({
            mousemove: this.bound.drag,
            mouseup: this.bound.stop,
         });
         document.body.addEvents({
            touchmove: this.bound.drag,
            touchend: this.bound.stop,
         });
         this.fireEvent('start', [this.element, event]).fireEvent('snap', this.element);
      }
   },

   cancel: function (event) {
      document.body.removeEvents({
         touchmove: this.bound.check,
         touchend: this.bound.cancel,
      });
      return this.previous.apply(this, arguments);
   },

   stop: function (event) {
      document.body.removeEvents({
         touchmove: this.bound.drag,
         touchend: this.bound.stop,
      });
      return this.previous.apply(this, arguments);
   },
});

// eslint-disable-next-line no-var
export var RangeSlider = (window.RangeSlider = new Class({
   Implements: [Options, Events],

   options: {
      type: 'double',
      range: [0, 100],
      position: [null, null],
      steps: null,
      precision: 0,
      knobClasses: '',
      rangeClasses: '',
   },

   initialize: function (element, options) {
      this.initOptions(options);

      this.element = element;

      this.rangeElement = new Element('span', {
         class: this.options.rangeClasses,
      });

      this.knobs = [
         new Element('div', { class: this.options.knobClasses }),
         new Element('div', { class: this.options.knobClasses }),
      ];

      let emptyDiv = new Element('div', {
         class: 'sliderGutter',
      });

      this.element.adopt(this.rangeElement, this.knobs[0], this.knobs[1], emptyDiv);

      this.initOrentation();
      this.initDimensions();
      this.prepareElements();
      this.updateKnobRange();

      this.fireEvent('change', [
         Math.round(this.position[0], this.options.precision),
         Math.round(this.position[1], this.options.precision),
      ]);

      if (this.type === 'single') {
         this.knobs[1].toggle();
      }
   },

   initOptions: function (options) {
      this.setOptions(options);

      // These are the most important options, so we make them easier to access.
      this.type = this.options.type;
      this.previousPosition = this.position = this.options.position;

      this.position[0] = this.clamp(this.options.position[0]);
      this.position[1] = this.clamp(this.options.position[1]);

      this.options.knobClasses += ' sliderKnob';
      this.options.rangeClasses += ' sliderRange';
   },

   initOrentation: function () {
      let size = this.element.getSize();
      if (size.x >= size.y) {
         Object.append(this, {
            axis: 'x',
            property: 'left',
            sizeProperty: 'width',
         });
      } else {
         Object.append(this, {
            axis: 'y',
            sizeProperty: 'height',
         });
      }
   },

   initDimensions: function () {
      this.offset = this.options.range[0];
      this.pixelRange = this.element.getSize()[this.axis];
      this.range = this.options.range[1] - this.options.range[0];
      this.grid = this.options.steps !== null ? this.pixelRange / this.options.steps : null;
   },

   prepareElements: function () {
      let locations = this.getKnobLocations(this.options.position[0], this.options.position[1]);

      this.knobs[0].setStyle(this.property, locations[0]);
      this.knobs[1].setStyle(this.property, locations[1]);

      this.updateRangeElement();

      // Init Drag Objects
      let dragOptions = {
         unit: 'px',
         modifiers: { x: 'left', y: 'top' },
         onComplete: this.onComplete.bind(this),
         onDrag: this.onChange.bind(this),
      };

      // Init Grid if necessary
      if (this.grid !== null) {
         dragOptions.grid = this.grid;
      }

      // Init Knob-Drags
      this.drags = [new Drag(this.knobs[0], dragOptions), new Drag(this.knobs[1], dragOptions)];

      // TODO: Click Functionality?
      //this.rangeElement.addEvent('click', function(click) {
      //var knobValues = this.getKnobValues();
      //var x = click['event']['layerX'];
      //var y = click['event']['layerY'];
      //console.log(x, y, knobValues); @suppress console me
      //}.bind(this));
   },

   getKnobOffset: function (knobIndex) {
      return this.knobs[knobIndex].getStyle(this.property).toInt();
   },

   getKnobSize: function (knobIndex) {
      return this.knobs[knobIndex].getSize()[this.axis].toInt();
   },

   clamp: function (value) {
      let min = this.options.range[0];
      let max = this.options.range[1];

      if (value === null) {
         return min;
      }

      return Math.min(Math.max(value, min), max);
   },

   updateRangeElement: function () {
      let posKnob1 = this.getKnobOffset(0);
      if (this.type === 'single') {
         this.rangeElement.setStyle(this.property, '0');
         this.rangeElement.setStyle(this.sizeProperty, posKnob1 + this.getKnobSize(0) + 'px');
      } else {
         let posKnob2 = this.getKnobOffset(1);

         this.rangeElement.setStyle(this.property, posKnob1 + 'px');
         this.rangeElement.setStyle(
            this.sizeProperty,
            posKnob2 - posKnob1 + this.getKnobSize(1) + 'px'
         );
      }
   },

   updateKnobRange: function () {
      let limit = { x: null, y: null };

      Object.each(
         limit,
         function (item, key, arr) {
            if (key != this.axis) {
               limit[key] = [0, 0];
            }
         },
         this
      );

      if (this.type === 'single') {
         this.drags[0].options.limit = limit;
         this.drags[0].options.limit[this.axis] = [0, this.pixelRange - this.getKnobSize(0)];
      } else {
         this.drags[0].options.limit = Object.clone(limit);
         this.drags[0].options.limit[this.axis] = [0, this.getKnobOffset(1) - this.getKnobSize(0)];

         this.drags[1].options.limit = limit;
         this.drags[1].options.limit[this.axis] = [
            this.getKnobOffset(0) + this.getKnobSize(0),
            this.pixelRange - this.getKnobSize(1),
         ];
      }
   },

   updatePosition: function (min, max) {
      this.position = this.getKnobValues();
   },

   onStart: function (e) {
      this.fireEvent('start');
   },

   getKnobValues: function () {
      if (this.type === 'single') {
         let estimate =
            (this.getKnobOffset(0) * this.range) / (this.pixelRange - this.getKnobSize(0));
         let value = Math.round(estimate, this.options.precision) + this.offset;
         return [value, value];
      }

      let doublePixelRange = this.pixelRange - (this.getKnobSize(0) + this.getKnobSize(1));
      let estimateMin = (this.getKnobOffset(0) * this.range) / doublePixelRange;
      let estimateMax =
         ((this.getKnobOffset(1) - this.getKnobSize(1)) * this.range) / doublePixelRange;

      return [
         Math.round(estimateMin, this.options.precision) + this.offset,
         Math.round(estimateMax, this.options.precision) + this.offset,
      ];
   },

   getKnobLocations: function (valueMin, valueMax) {
      if (this.type === 'single') {
         if (valueMin === this.options.range[0]) {
            valueMin = valueMax;
         }
         let singlePixelRange = this.pixelRange - this.getKnobSize(0);
         let value = Math.round((singlePixelRange * valueMin) / this.range, this.options.precision);
         return [value + 'px', value + this.getKnobSize(1) + 'px'];
      }

      let doublePixelRange = this.pixelRange - (this.getKnobSize(0) + this.getKnobSize(1));
      return [
         Math.round((doublePixelRange * valueMin) / this.range, this.options.precision) + 'px',
         Math.round((doublePixelRange * valueMax) / this.range, this.options.precision) +
            this.getKnobSize(1) +
            'px',
      ];
   },

   onChange: function (e) {
      this.previousPosition = [null, null];
      this.updatePosition();
      this.updateRangeElement();
      this.fireEvent('change', this.position);
   },

   onComplete: function (e) {
      this.updatePosition();
      this.updateKnobRange();
      this.updateRangeElement();
      this.fireEvent('complete', this.position);
   },

   toggleType: function () {
      this.knobs[1].toggle();
      if (this.type === 'single') {
         this.type = 'double';

         if (this.previousPosition[0] !== null) {
            this.set(this.previousPosition[0], this.previousPosition[1]);
         } else {
            this.previousPosition = this.position;
            this.set(this.position[0], this.position[1]);
         }
      } else {
         this.type = 'single';

         if (this.previousPosition[0] !== null) {
            if (this.previousPosition[0] === this.options.range[0]) {
               this.set(this.previousPosition[1]);
            } else {
               this.set(this.previousPosition[0]);
            }
         } else {
            this.previousPosition = this.position;
            this.set(this.position[1]);
         }
      }
   },

   set: function (first, second) {
      if (typeof second === 'undefined') {
         second = first;
      } else if (second < first) {
         let temp = first;
         first = second;
         second = temp;
      } else if (second == first) {
         if (first == this.options.range[0]) {
            second += 1;
         } else {
            first -= 1;
         }
      }

      let locations = this.getKnobLocations(first, second);
      this.knobs[0].setStyle(this.property, locations[0]);
      this.knobs[1].setStyle(this.property, locations[1]);

      this.onComplete();
   },
}));
