import { Autocompleter } from 'Shared/Autocompleter/Autocompleter';

// eslint-disable-next-line no-var
export var UserFinder = (window.UserFinder = new Class({
   Extends: Autocompleter.Request.API_2_0,

   el: null,

   initialize: function (el, options) {
      if (!App.noAvatarUrl) {
         console.error('App.noAvatarUrl is not set.');
      }
      options = Object.merge(
         {
            className: 'blurb-finder',
            fxOptions: null,
            maxChoices: 5,
            delay: 100,
            truncateText: false,
            overflow: false,
            showReputation: true,
            injectChoice: this.injectChoice.bind(this),
         },
         options
      );

      this.el = el;

      this.parent(this.el, 'users/search', options);
      this.url = this.request.request.options.url;

      // Prevent blur event from firing in Safari when dragging the scrollbar
      this.choices.addEvent('mousedown', function (ev) {
         ev.stop();
      });
   },

   query: function () {
      this.toggleIndicator(true);

      let data = Object.clone(this.options.getData) || {};
      this.request.request.options.url = this.url + '/' + this.queryValue;
      this.fireEvent('onRequest', [this.element, this.request, data, this.queryValue]);
      this.request.send({ data: data });
   },

   injectChoice: function (user) {
      if (!this.el.isDisplayed()) {
         return;
      }
      let url;
      if (user.image && user.image.mini) {
         url = user.image.mini;
      } else {
         url = App.noAvatarUrl;
      }

      let data = {
         user: user,
         userImage: url,
         showImage: true,
         reputationTitle: _js('Reputation'),
         blurbId: 'user-' + user.type + '-' + user.id,
         showReputation: this.showReputation,
      };

      let template = window.hbsTemplates('USER_HBS');

      let choice = template(data);

      choice.store('user', user);
      choice.inputValue = '';

      this.addChoiceEvents(choice).inject(this.choices);
   },
}));
