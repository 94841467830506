import { Utils } from 'Shared/utils';

/**
 * This should be included with the `preferences.phtml` template.
 */
onDomReady(function () {
   /**
    * Clicking an individual radio button should save just that preference.
    */
   $$('.preferences-edit input').addEvent(
      'click',
      LoadingIndicator.withEventHandler(
         function (ev) {
            // Don't change which radio button is selected. We'll do that once the
            // API request is successful.
            ev.preventDefault();

            let preference = this.getParent('.preference');
            let newValue = this.get('value');

            return savePreferences(new Elements([preference]), newValue);
         },
         {
            // Don't disable this function from running multiple times, because it is
            // valid for the user to save a preference while another preference is
            // still saving.
            disableEventHandler: false,
            // Disable the radio buttons for this specific preference, however.
            inputTypesToDisable: '.preference, .ifixit-control',
         }
      )
   );

   /**
    * Clicking a header should save that value for every preference in the
    * group.
    */
   $$('.preferences-edit .group-label').addEvent(
      'click',
      LoadingIndicator.withEventHandler(
         function (ev) {
            // Get all of the preferences within this group.
            let preferences = this.getParent('.value-group').getElements('.preference');

            let newValue = this.get('data-value');

            return savePreferences(preferences, newValue);
         },
         {
            disableEventHandler: false,
            inputTypesToDisable: '.value-group, .ifixit-control',
         }
      )
   );

   $$('.js-preference-dropdown').addEvent(
      'valuechanged',
      LoadingIndicator.withEventHandler(
         function (el) {
            const preference = this.getParent('.preference');
            const newValue = el.getValue();

            return savePreferences(new Elements([preference]), newValue);
         },
         {
            // Don't disable this function from running multiple times, because it is
            // valid for the user to save a preference while another preference is
            // still saving.
            disableEventHandler: false,
            // Disable the radio buttons for this specific preference, however.
            inputTypesToDisable: '.preference, .ifixit-control',
         }
      )
   );

   /**
    * Save each preference with the value `newValue`.
    *
    * `preferences` is an Elements array of `fieldset.preference`s, which
    * contain all the radio buttons for one preference.
    */
   function savePreferences(preferences, newValue) {
      // Filter out any preferences that already have the desired value.
      preferences = preferences.filter(':not([data-value="' + newValue + '"])');

      // If there's nothing left to save, don't do anything.
      if (preferences.length == 0) {
         return null;
      }

      // Build the API request body by assinging newValue to each preference.
      let data = {};
      preferences.each(function (preference) {
         data[preference.get('data-name')] = newValue;
      });

      return new Request.API_2_0(App.preferencesAPIPath, {
         method: 'PATCH',
      })
         .send(data)
         .then(function () {
            // Once the request is successful, update the displayed value for
            // that preference.
            preferences.set('data-value', newValue);

            // `data-value` is what we check to get the preference's current
            // value, but we still need to visually update each radio button's
            // `checked` attribute to match `data-value`.
            preferences.each(function (preference) {
               preference
                  .getElements('input')
                  .removeProperty('checked')
                  .filter('[value="' + newValue + '"]')
                  .set('checked', 'checked');
            });

            // This is hacky, but makes the radio button animation look better
            // if it starts before the radio buttons get re-enabled.
            return Utils.timeoutPromise(100);
         });
   }
});
