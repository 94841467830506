import { Utils } from 'Shared/utils';
import { Button } from 'Shared/button-toggle';
import { FrameModules } from 'Shared/frame_modules';

FrameModules.add('OGDCSettingsFrameModule', function () {
   new OGDCStatusSettings();
});

// eslint-disable-next-line no-var
export let OGDCStatusSettings = (window.OGDCStatusSettings = new Class({
   ajaxSpinner: new Element('img', {
      src: window.shared_constants.BaseURI('SITE_IMAGE_AJAX_LOAD'),
   }),

   initialize: function () {
      this.form = document.id('ogdcStatusSettings');
      this.guideid = this.form.getProperty('data-guideid');

      this.ogdcStatusToggle = new Button.Toggle('ogdcStatusToggle', {
         callback: this.toggle.bind(this),
      });
   },

   buildAjaxSpinner: function (elClass) {
      let element = new Element('span', {
         class: 'ajax-spinner ' + elClass,
      });

      return element.adopt(this.ajaxSpinner);
   },

   toggle: function (ev) {
      let el = ev.target,
         self = this,
         enabled = el.get('data-value') == 'enabled';
      $('ogdcStatusSettingsField').adopt(self.buildAjaxSpinner('ogdcStatusEnable'));
      new Request.AjaxIO('setOGDCStatusEnabled', {
         onSuccess: function (response) {
            $$('.ajax-spinner').dispose();

            if (response.error) {
               let msg = Utils.createAlert('error', response.error);
               $('ogdcStatusSettingsErrors').grab(msg, 'top');
               self.ogdcStatusToggle.untoggle();
               return;
            }

            $$('.form-builder').toggle(response.ogdcStatusEnabled);
         },
      }).send(self.guideid, enabled);
   },
}));
