import * as React from 'react';
import SmallBanner from './small_banner';
import LargeBanner from './large_banner';
import EUBanner from './eu_banner';
import { withBreakpointsContext } from 'core-components';

const Banner = ({ index, numBanners, bannerData }) => {
   const { type } = bannerData;

   switch (type) {
      case 'small':
         return <SmallBanner index={index} numBanners={numBanners} bannerData={bannerData} />;
      case 'large':
         return <LargeBanner index={index} numBanners={numBanners} bannerData={bannerData} />;
      case 'eu':
         return <EUBanner index={index} numBanners={numBanners} bannerData={bannerData} />;
      default:
         return null;
   }
};

export default withBreakpointsContext(Banner);
