import { Utils } from 'Shared/utils';
import { MediaItem } from './item';
import MediaLibrary from './library';
import { setValidChoiceClass } from './item_display';
import { getFormattedFilename } from './display_name_formatter';

/**
 * Class MediaItemImage
 *
 * Represents a MediaItem of type image.
 */
export const MediaItemImage = (window.MediaItemImage = new Class({
   Extends: MediaItem,

   /**
    * Returns true if this media Item represents an image that has been marked
    * up.
    */
   hasMarkers: function () {
      let markup = this.data.markup();
      return markup && markup.draw;
   },

   /**
    * Returns true if this media Item represents an image that has been
    * cropped.
    */
   hasCrop: function () {
      let markup = this.data.markup();
      return markup && markup.crop;
   },

   /**
    * Returns just the portion of the markup string that does cropping
    */
   cropMarkupString: function () {
      if (!this.hasCrop()) {
         return '';
      }
      return this.data.markup_string().split(';')[1];
   },

   /**
    * Returns the portion of the markup string that draws the markers
    */
   markersString: function () {
      let markup = this.data.markup_string();
      if (!this.hasCrop()) {
         return markup;
      }
      return markup.split(';').slice(2).join(';');
   },

   /**
    * Returns a list of menu functions that should be allowed
    * for this media item in it's current state.
    *
    */
   _getMenuOptions: function () {
      let filter_state = this.data.filter_state();
      let canCrop = true;

      /**
       * If (we're in the media library, under the context of placing an image,
       *     and a user chooses to crop via the menu and the filter_state is an
       *     error message) {
       *        canCrop = the error message
       *     }
       */
      if (this.getContext() === MediaLibrary && typeOf(filter_state) == 'object') {
         canCrop = filter_state && filter_state.message;
      }

      return {
         // All images allow markers
         markers: true,
         // Almost all images can be cropped
         crop: canCrop,
         // All iamges can have their largest sizes viewed
         fullsize: true,
      };
   },

   /**
    * Creates an appropriate representation for the given MediaTarget and
    * passes it as the only argument to the provided callback when it's
    * ready.
    */
   createRepresentationFor: function (mediaTarget, callback) {
      let size = mediaTarget.options.displaySize;
      let self = this;
      self.whenReady(function () {
         let thumb = new MediaItemImageDisplay(self, {
            size: size,
            useBackground: false, //size === 'thumbnail'
         });
         thumb.whenReady(function () {
            callback(thumb);
         });
      });
   },

   /**
    * Instantiates a MediaItemImageDisplay for this media image
    * whose element will automatically update to reflect any state
    * changes in this item.
    *
    * @param size:string one of the image sizes, default: thumbnail
    * @param showFilename:bool default: false
    */
   createDisplay: function (size, showFilename) {
      let thumb = new MediaItemImageDisplay(this, {
         size: size,
         useBackground: false,
         showFilename: showFilename,
      });
      return thumb;
   },

   /**
    * Returns the url for this image for the specified size.
    *
    * size: 'thumbnail', 'medium', ...
    */
   getSrc: function (size) {
      return this.data[size]();
   },

   /**
    * Returns a url for an uncropped version of this image (though still with markers).
    * If this image isn't cropped, it returns the url of this  image.
    */
   getUncroppedPreview: function (size) {
      if (!this.data.markup_string()) {
         return this.getSrc(size);
      }
      let base = window.shared_constants.GuideURI('PREVIEW_IMAGE');
      let id = this.data.srcid();
      return base + '/' + id + '/' + this.markersString() + '/' + size;
   },

   /**
    * Returns the underlying imageid that this mediaItem represents.
    */
   getID: function () {
      return this.data.imageid();
   },

   getEncoding: function () {
      return this.data.encoding();
   },

   getType: function () {
      return this.data.type();
   },

   getFilter: function () {
      return 'onlyImages';
   },

   toWikiText: function () {
      // Only add left align on wiki pages.
      if (typeof App.wikiTitle !== 'undefined') {
         return '[image|' + this.getID() + '|align=left]';
      }

      return '[image|' + this.getID() + ']';
   },

   /**
    * Returns the media type and underlying imageid that this mediaItem
    * represents.
    */
   getGlobalID: function () {
      return 'image:' + this.getID();
   },
}));

MediaItem.registerType('GuideImage', MediaItemImage);

/**
 * Represents a DOM element that displays a MediaItemImage
 *
 * options: {
 *    size: one of ('thumbnail, 'small', 'medium', ...). Default: 'thumbnail'
 *
 *    useBackground: if true, the the media item will use a background image to
 *       instead of an img tag. Default: false
 *
 *    showFilename: if true, a filename will be shown on top of the created UI.
 *       Default: false
 * }
 *
 * Fires Events:
 *    select(mediaItem): when the DOM element is clicked
 */
export { MediaItemImageDisplay };
function MediaItemImageDisplay(mediaItem, options) {
   options = options || {};
   let data = mediaItem.data;
   let mediaClass = options.classOverride || 'mediaImage';
   let self = this;
   this.size = options.size || 'standard';
   this.displayType = options.useBackground ? 'background' : 'img';
   this.mediaItem = mediaItem;

   // The container that holds everything.
   let container = (this.container = new Element(
      'div.mediaItem.' + mediaClass + '[data-imageid="' + mediaItem.getID() + '"]'
   ));

   // Add a menu control (defaults to visible, false === hide)
   if (options.menu !== false) {
      container.adopt(MediaItem.createMenuControl(mediaItem));
   }

   if (options.showFilename) {
      let filecontainer = new Element('span.file-container');
      let filetypeContainer = new Element('div.filetypeContainer');
      this.filename = new Element('p.filename');
      this.filetype = new Element('div.filetype');
      let icon = new Element('i.fileicon.fa.fa-picture-o');

      this._updateFilename();

      filecontainer.grab(this.filename);
      container.grab(filetypeContainer);
      container.grab(filecontainer);
      container.grab(this.filetype);
      container.grab(icon);
   }

   // Listen for click and fire "select" (the library listens for this)
   container.addEvent('click', this.fireEvent.pass(['select', mediaItem], this));

   // Store a reference to this instance with the DOM element, so that other
   // code can always get back to the class from the DOM.
   this.container.store('mediaItem', mediaItem);

   mediaItem.addEvents({
      dataChanged: this._updateDisplay.bind(this),
      loadingChanged: this._updateLoading.bind(this),
      deleted: this.dispose.bind(this),
   });

   if (mediaItem.isReady()) {
      self._updateDisplay();
   } else {
      self._updateLoading(true);
   }
}

Object.append(MediaItemImageDisplay.prototype, {
   /**
    * Returns the DOM element that this instance represents.
    * This is the element that should be injected into the page.
    */
   getElement: function () {
      return this.container;
   },

   /**
    * Calls the readyCallback once the image is in the browser cache.
    *
    * Once the callback is called, it's safe to place this element in the DOM
    * without fear of long load-times.
    */
   whenReady: function (readyCallback) {
      if (this._elementPromise) {
         return this._elementPromise.getValue(readyCallback);
      } else {
         if (readyCallback) {
            readyCallback();
         }
         return true;
      }
   },

   /**
    * Updates the DOM element to reflect the current state of the
    * media item.
    */
   _updateDisplay: function () {
      let mediaItem = this.mediaItem;
      let self = this;
      const size = mediaItem.getLargestImageSize(self.size);
      let imgUrl = mediaItem.getSrc(size || self.size);
      let promise = (this._elementPromise = new Future());

      setValidChoiceClass(mediaItem.isValidChoice(), this.container);

      // Wait until the image we want to show is cached.
      new Asset.image(imgUrl, {
         onLoad: updateImgTag,
      });

      function updateImgTag() {
         if (self.displayType == 'background') {
            let contents = (self._displayElement = new Element('div.contents.' + size));
            contents.setStyle('backgroundImage', 'url(' + imgUrl + ')');
            self.container.grab(contents);
         } else {
            let img = self._displayElement;
            if (!img) {
               img = self._displayElement = new Element('img.' + size);
               self.container.grab(img);
            }
            img.set('src', imgUrl);
         }

         self._updateFilename();

         // Give the UI thread a chance to catch up.
         (function () {
            promise.resolve(imgUrl);
         }.delay(10));
      }
   },

   _updateFilename: function () {
      if (!this.filename) {
         return;
      }

      const mediaItem = this.mediaItem;
      const name = mediaItem.data.filename();

      if (!name) {
         return;
      }

      this.filename.setProperties({
         text: getFormattedFilename(name.substr(0, name.lastIndexOf('.'))),
         title: name,
      });

      this.filetype.setProperties({
         text: name.substr(name.lastIndexOf('.') + 1).toUpperCase(),
      });

      // Hide or show the filename element
      this.filename.toggle(name);
   },

   _updateLoading: function (isLoading) {
      let overlay = this._overlay();
      if (isLoading) {
         this.container.grab(overlay);
      } else {
         this.whenReady(function () {
            overlay.dispose();
         });
      }
      this._updateFilename();
   },

   dispose: function () {
      this.container.dispose();
      if (this.filename) {
         this.filename.dispose();
      }
      this.removeEvents();
   },

   _overlay: function () {
      this._overlayEl = this._overlayEl || new Element('div.loadingOverlay');
      return this._overlayEl;
   },

   inject: function (intoElement, option) {
      return this.container.inject(intoElement, option || 'after');
   },

   hide: function () {
      return this.container.hide();
   },

   show: function () {
      return this.container.show();
   },
});

// Allow Thumbnails to fire events
Object.append(MediaItemImageDisplay.prototype, Utils.EventsFunctions);
