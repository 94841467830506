import { Auth } from 'Shared/auth';
import { Utils } from 'Shared/utils';
import { FrameModules } from 'Shared/frame_modules';

FrameModules.add('StepListEditFrameModule', function () {
   new StepIndex();
});

// The collection of images from steps which lives in the sidebar, and can be
// used to rearrange steps with drag-drop.
// eslint-disable-next-line no-var
export var StepIndex = (window.StepIndex = new Class({
   initialize: function () {
      this.sides = [];
      this.editing = false;
      this.modified = false;
      this.container = document.id('guideStepIndex');
      this.savingMsg = document.id('savingReorder');
      this.errorMsg = document.id('reorderError');

      let steps = this.gatherSteps();

      // Highlight the hovered step thumbnail.
      this.addHoverEvents(steps);

      // Allow an edit mode, where steps can be rearranged by dragging their
      // thumbnails around.
      if (!$('guideStepIndex').hasClass('locked')) {
         this.setOriginalStepIndexes(steps);

         this.toggleLink = document.getElement('#toggleStepReorder a');
         this.toggleLink.addEvent('click', this.beginEditMode.bind(this));

         this.saveControl = document.id('saveReorder');

         this.saveControl.getElement('a[href$=save]').addEvent('click', this.save.bind(this));

         this.saveControl.getElement('a[href$=cancel]').addEvent('click', this.cancel.bind(this));
      }

      Utils.UnsavedChanges.addHandler(
         function () {
            if (this.modified) {
               return _js('You have unsaved changes. Your changes will be lost if you continue.');
            }
         }.bind(this)
      );
   },

   addHoverEvents: function (steps) {
      steps.each(function (step) {
         step.addEvents({
            mouseenter: this.setActive.pass([step, true], this),
            mouseleave: this.setActive.pass([step, false], this),
         });
         this.setActive(step, false);
      }, this);

      when($E('.guideSidebarAddStep'), function (div) {
         let off = 'left -84px';
         let on = 'left -126px';
         div.addEvents({
            mouseenter: div.setStyle.pass(['background-position', on], div),
            mouseleave: div.setStyle.pass(['background-position', off], div),
         });
      });
   },

   addDragEvents: function () {
      let steps = this.gatherSteps();

      if (steps.length < 1) {
         return;
      }

      let first = steps[0];
      this.stepStyle = first.getStyles('width', 'height');

      let firstTime = this.sides.length == 0;
      steps.each(function (step, index) {
         if (firstTime) {
            // Inserting before.
            let left = new Element('div.left').setStyles({
               display: 'none',
               position: 'absolute',
               top: 0,
               left: 0,
               width: '50%',
               height: '100%',
            });
            left.inject(step);
            this.sides.push(left);

            // Inserting after.
            let right = new Element('div.right').setStyles({
               display: 'none',
               position: 'absolute',
               top: 0,
               right: 0,
               width: '50%',
               height: '100%',
            });
            right.inject(step);
            this.sides.push(right);
         }

         step.getElement('a').addEvents({
            mousedown: this.onMousedown.bind(this, step),
            click: function (ev) {
               ev.stop();
            },
         });
      }, this);
   },

   removeDragEvents: function () {
      this.gatherSteps().each(function (step) {
         let a = step.getElement('a');
         a.removeEvents('mousedown');
         a.removeEvents('click');
      });
   },

   setActive: function (step, active) {
      // Don't change the opacity around while rearranging steps.
      if (this.editing) {
         return;
      }

      let tint = step.getElement('a');
      let opacity, action;
      if (step.hasClass('current')) {
         tint.setStyles({
            opacity: 0.01,
            backgroundColor: 'transparent',
         });
      } else {
         if (active) {
            opacity = 0.01;
            action = 'addClass';
         } else {
            opacity = 0.3;
            action = 'removeClass';
         }
         tint.setStyles({
            opacity: opacity,
            backgroundColor: '#3c5d8b',
         });
         step[action]('active');
      }
   },

   onMousedown: function (step, ev) {
      ev.stop();

      let start = ev.page;

      // eslint-disable-next-line no-var
      var onMove = function (ev) {
         ev.stop();
         if (Math.abs(ev.page.x - start.x) >= 1 || Math.abs(ev.page.y - start.y) >= 1) {
            document.removeEvent('mousemove', onMove);
            document.removeEvent('mouseup', onUp);
            this.startDrag(ev, step);
         }
      }.bind(this);

      // eslint-disable-next-line no-var
      var onUp = function (ev) {
         document.removeEvent('mousemove', onMove);
         document.removeEvent('mouseup', onUp);
      }.bind(this);

      document.addEvent('mousemove', onMove);
      document.addEvent('mouseup', onUp);
   },

   startDrag: function (ev, step) {
      // Get the offset relative to the sidebar.
      let stepCoords = step.getCoordinates();
      let body = $$('body')[0];

      let ghost = new Element('div.ghostStep').setStyles(
         Object.append(this.stepStyle, {
            position: 'absolute',
            top: stepCoords.top,
            left: stepCoords.left,
            border: '1px dashed black',
            zIndex: 1000,
         })
      );

      body.adopt(ghost);
      ghost.store('step', step);

      step.setStyle('opacity', 0);

      // Enable the sides (which are normally display:none so that the user can
      // interact with the embedded links and step numbers, which would
      // otherwise be covered).
      this.sides.invoke('show');

      ghost
         .makeDraggable({
            droppables: this.sides,
            container: $('guideStepIndex'),
            onEnter: this.onEnter.bind(this),
            onDrop: this.onDrop.bind(this),
            snap: 0,
            preventDefault: true,
            unDraggableTags: [],
         })
         .start(ev);
   },

   onEnter: function (ghost, side) {
      let isLeft = side.hasClass('left');
      let original = ghost.retrieve('step');
      original.inject(side.getParent(), isLeft ? 'before' : 'after');
   },

   onDrop: function (ghost, side) {
      let original = ghost.retrieve('step');
      let left = original.getPrevious('.guideSidebarThumb');

      ghost.dispose();
      original.setStyle('opacity', 1);

      // Hide the sides (see startDrag).
      this.sides.invoke('hide');

      this.modified = true;
   },

   // SAVING AND CANCELING ////////////////////////////////////////////////////

   beginEditMode: function (ev) {
      ev.stop();
      this.showError(false);
      this.addDragEvents();
      this.saveControl.replaces(this.toggleLink).show();
      this.container.addClass('reorderingSteps');
      this.editing = true;
      this.modified = false;
   },

   endEditMode: function () {
      this.removeDragEvents();
      this.container.removeClass('reorderingSteps');
      this.editing = false;
      this.modified = false;
   },

   hideError: function () {
      showError();
   },

   showError: function (message) {
      if (message) {
         this.errorMsg.set('text', message);
         this.errorMsg.show();
         this.errorMsg.dissolve.delay(5000, this.errorMsg);
      } else {
         this.errorMsg.hide();
      }
   },

   save: function (ev) {
      ev.stop();
      Auth.required({
         onAuthorize: this.requestSave.bind(this),
      });
   },

   requestSave: function (ev) {
      let steps = this.gatherSteps();
      let stepids = steps.map(function (step) {
         return step.get('data-stepid').toInt();
      });
      this.savingMsg.replaces(this.saveControl).show();

      let path = this.getStepReorderPath(App.guideid, App.guide_revisionid);
      new Request.API_2_0(path, {
         method: 'PUT',
         onSuccess: function (response) {
            App.guide_revisionid = response.revisionid;
            this.finishSave(steps);
         }.bind(this),
         onFailure: function (xhr, message) {
            this.showError(message);
            this.toggleLink.replaces(this.savingMsg);
            this.endEditMode();
         }.bind(this),
      }).send({ stepids: stepids });
   },

   getStepReorderPath: function (guideid, revisionid) {
      return 'guides/' + guideid + '/steporder?revisionid=' + revisionid;
   },

   finishSave: function (steps) {
      // Re-number the steps so that they're in order again.
      steps.each(function (step, index) {
         let number = step.getElement('.stepNumber');
         number.set('text', index + 1);
      });

      // Reset the original indexes.
      this.setOriginalStepIndexes(steps);

      // If the current step has been renumbered, we need to change the text on
      // the actual step navigation.
      let stepIndex = $('guideStepIndex');
      let current = stepIndex.getElement('.current');

      if (current) {
         let left = current.getPrevious('.guideSidebarThumb');
         // eslint-disable-next-line no-var
         var right = current.getNext('.guideSidebarThumb');

         when(
            $E('.orderby'),
            function (orderbySpan) {
               let index = current.retrieve('originalIndex');
               orderbySpan.set('text', index);
            }.bind(this)
         );

         when(
            $$('.prevLink'),
            function (prevLinks) {
               prevLinks.each(function (link) {
                  let text = link.getElement('.prevLinkText');
                  if (left) {
                     link.set('href', left.getElement('a').get('href'));
                     text.set('text', _js('Previous'));
                  } else {
                     link.set('href', link.get('href').replace('edit', 'intro'));
                     text.set('text', _js('Introduction'));
                  }
               }, this);
            }.bind(this)
         );
      }

      when(
         $$('.nextLink'),
         function (nextLinks) {
            nextLinks.each(function (link) {
               if (right) {
                  link.set('href', right.getElement('a').get('href'));
                  link.show();
               } else if (!current) {
                  // If we're on Meta, link to the first step, otherwise if
                  // we're on Intro, do nothing
                  if (link.get('text').substring(0, 10) == _js('First step')) {
                     link.set('href', stepIndex.getElement('a').get('href'));
                  }
               } else {
                  link.hide();
               }
            }, this);
         }.bind(this)
      );

      this.toggleLink.replaces(this.savingMsg);
      this.endEditMode();
   },

   cancel: function (ev) {
      ev.stop();
      let steps = this.gatherSteps();

      // Sort the steps back into their original order.
      steps.sort(function (a, b) {
         return a.retrieve('originalIndex') - b.retrieve('originalIndex');
      });

      let container = $('thumbsContainer');
      steps.each(function (step) {
         step.inject(container, 'bottom');
      });

      this.toggleLink.replaces(this.saveControl);
      this.endEditMode();
   },

   // HELPERS /////////////////////////////////////////////////////////////////

   gatherSteps: function () {
      return document.getElements('.guideSidebarThumb');
   },

   inOrder: function (steps) {
      for (let i = 0; i < steps.length; i++) {
         if (steps[i].retrieve('originalIndex') != i + 1) {
            return false;
         }
      }
      return true;
   },

   /**
    * Called on initialization, and each time a new step layout is saved.
    */
   setOriginalStepIndexes: function (steps) {
      steps.each(function (step, index) {
         step.store('originalIndex', index + 1);
      });
   },
}));
