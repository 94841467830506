import { Modal } from 'Shared/modal';
import { Auth } from 'Shared/auth';
import { Tooltip } from 'Shared/tooltips';
import { FrameModules } from 'Shared/frame_modules';

FrameModules.add('UserLikeFrameModule', function () {
   document.getElements('.js-favorite').each(function (el) {
      new LikeMeControl(el);
   });
});

// eslint-disable-next-line no-var
export var LikeMeControl = (window.LikeMeControl = new Class({
   el: null,
   count: null,
   doctype: null,
   docid: null,
   action: null,
   requesting: false,
   likedClass: 'fa-heart',
   unlikedClass: 'fa-heart-o',

   initialize: function (el) {
      this.el = el;
      this.control = el.hasClass('control') ? el : el.getElement('.control');
      this.useTooltip = el.getProperty('data-useTooltip') == 'true';
      this.iconEl = el.getElement('.js-icon-el');
      this.unlikedIcon = el.querySelector('.js-unliked-icon');
      this.likedIcon = el.querySelector('.js-liked-icon');
      this.spinnerIcon = el.querySelector('.js-spinner-icon');
      this.countEl = el.getElement('.likeCount') || $$('.js-like-count');
      this.textEl = el.getElement('.likeText');
      this.doctype = el.get('data-doctype');
      this.docid = el.get('data-docid');

      this.likedText = this.control.getProperty('data-likedText');
      this.unlikedText = this.control.getProperty('data-unlikedText');
      this.likedClass = this.control.getProperty('data-likedIcon') || this.likedClass;
      this.unlikedClass = this.control.getProperty('data-unlikedIcon') || this.unlikedClass;

      this.likeInfo = App.userLikeInfo[this.doctype + '_' + this.docid];
      if (this.likeInfo !== undefined) {
         this.initializeInterface();
      } else {
         // Does the current user Like?
         new Request.AjaxIO('ajaxDoesLike', {
            onSuccess: function (response) {
               this.likeInfo = response;
               this.initializeInterface();
            }.bind(this),
         }).send(this.doctype, this.docid);
      }

      this.control.addEvents({
         mouseenter: this.entered.bind(this),
         mouseleave: this.left.bind(this),
         click: function (ev) {
            ev.stop();
            this.clicked();
         }.bind(this),
      });
   },

   initializeInterface: function () {
      if (this.useTooltip) {
         Tooltip.instance(this.control, {
            side: 'top',
            align: 'left',
            autoshow: true,
         });
      }

      this.count = this.likeInfo.count;

      if (this.countEl) {
         this.countEl.set('text', this.count);
      }

      if (this.likeInfo.likes) {
         this.setLiked();
      } else if (!this.likeInfo.notLoggedIn) {
         this.setUnliked();
      } else {
         this.setNoUpdate();
      }

      // Spinning looks weird when applied to the <i> element, so apply
      // the spinning class to the child <svg> element.
      this.spinnerIcon.querySelector('svg').classList.add('fa-spin');

      this.el.makeVisible();
   },

   entered: function () {
      this.showIcon(this.likeInfo.likes ? this.unlikedIcon : this.likedIcon);
   },

   left: function () {
      this.showIcon(this.likeInfo.likes ? this.likedIcon : this.unlikedIcon);
   },

   clicked: function () {
      this.favorite();
   },

   favorite: function () {
      if (this.requesting) {
         return;
      }

      Auth.required({
         message: _js('Log in to "favorite" this.'),
         onAuthorize: function () {
            if (this.useTooltip) {
               Tooltip.instance(this.control, _js('Updating...')).show();
            } else {
               this.showIcon(this.spinnerIcon);
            }
            this.requesting = true;

            let method;
            if (this.action === 'like') {
               method = 'PUT';
            } else if (this.action === 'unlike') {
               method = 'DELETE';
            }

            new Request.API_2_0('user/favorites/guides/' + this.docid, {
               method: method,
               onSuccess: function (response) {
                  let liked = this.action === 'like';

                  this.likeInfo.likes = liked;

                  if (liked) {
                     this.count += 1;
                     this.setLiked();
                  } else {
                     this.count -= 1;
                     this.setUnliked();
                  }
                  this.requesting = false;
               }.bind(this),
               onFailure: function (response, message) {
                  Modal.alert(message);
               },
            }).send({ langid: App.langid });
         }.bind(this),
      });
   },

   setLiked: function () {
      if (this.useTooltip) {
         Tooltip.instance(this.control, this.likedText);
      } else {
         this.textEl.set('text', this.likedText);
      }

      if (this.countEl) {
         this.countEl.set('text', this.count);
      }

      this.action = 'unlike';
      this.showIcon(this.likedIcon);
   },

   setUnliked: function () {
      if (this.useTooltip) {
         Tooltip.instance(this.control, this.unlikedText);
      } else {
         this.textEl.set('text', this.unlikedText);
      }

      if (this.countEl) {
         this.countEl.set('text', this.count);
      }

      this.action = 'like';
      this.showIcon(this.unlikedIcon);
   },

   setNoUpdate: function () {
      if (this.useTooltip) {
         Tooltip.instance(this.control, this.unlikedText);
      }
      this.el.addClass('noUser');
      this.showIcon(this.unlikedIcon);
   },

   showIcon: function (iconToShow) {
      this.likedIcon.classList.add('hidden');
      this.unlikedIcon.classList.add('hidden');
      this.spinnerIcon.classList.add('hidden');
      iconToShow.classList.remove('hidden');
   },
}));
