import { keyframes } from 'styled-components';

export const fadeIn = keyframes`
   0% {
      opacity: 0;
   }
`;

export const fadeOut = keyframes`
   100% {
      opacity: 0;
   }
`;

export const menuOpen = keyframes`
   0% {
      opacity: 0;
      transform: translateY(5%);
   }
`;

export const menuClose = keyframes`
   100% {
      opacity: 0;
      transform: translateY(-5%);
   }
`;

export const dropdownOpen = keyframes`
   0% {
      opacity: 0;
      transform: translateY(-10%);
   }
`;

export const dropdownClose = keyframes`
   100% {
      opacity: 0;
      transform: translateY(-10%);
   }
`;
