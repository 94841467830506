import trackInMatomoAndGA from 'Shared/Analytics/CombinedGAMatomo';

when($('helptab'), function (el) {
   let tabInit = function () {
      TweenMax.to(el, 0.6, {
         right: -95,
         ease: Bounce.easeOut,
      });
   };

   tabInit.delay(1000);
   el.addEvents({
      mouseenter: function () {
         TweenMax.to(el, 0.3, { right: -40 });
      },
      mouseleave: function () {
         TweenMax.to(el, 0.3, { right: -95 });
      },
      click: function () {
         trackInMatomoAndGA({
            eventCategory: 'Help Tab',
            eventAction: 'Click',
         });
         return true;
      },
   });
});
