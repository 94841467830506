import * as React from 'react';
import { createRef } from 'react';
import { CSRF } from 'Shared/csrf';

const withLanguageForm = LanguagesContainer => {
   return props => {
      const languageForm = createRef<HTMLFormElement>();
      const languageInput = createRef<HTMLInputElement>();
      const machineTranslationInput = createRef<HTMLInputElement>();

      const submitForm = () => {
         // Ensure CSRF handler was added to this form. Since the handler is
         // only installed on the 'domready' event, it will disappear on future
         // renders.
         CSRF.recheckAll();
         languageForm.current.submit();
      };

      const onClickLanguage = clickedLangId => {
         languageInput.current.value = clickedLangId;
         submitForm();
      };

      const onClickMachineTranslate = () => {
         machineTranslationInput.current.value = !props.machineTranslationRequested ? 'on' : '';
         submitForm();
      };

      return (
         <React.Fragment>
            <LanguagesContainer
               {...props}
               onClickLanguage={onClickLanguage}
               onClickMachineTranslate={onClickMachineTranslate}
            />
            <form ref={languageForm} method="POST" action={props.translationPreferencesUrl}>
               <input ref={languageInput} type="hidden" name="langid" value={''} />
               <input
                  ref={machineTranslationInput}
                  type="hidden"
                  name="machineTranslate"
                  value={props.machineTranslationRequested ? 'on' : ''}
               />
            </form>
         </React.Fragment>
      );
   };
};

export default withLanguageForm;
