/**
 * Example usage:
 *
 *    var tabs = new Tabs('tab_group');
 *    tabs.first();
 *    tabs.select('upload-photos');
 */
// eslint-disable-next-line no-var
export var Tabs = (window.Tabs = new Class({
   Implements: [Options, Events],

   options: {
      selector: 'li a',
      attribute: 'rel',
      id: /^(.+)$/,
   },

   initialize: function (group, options) {
      this.setOptions(options);
      this.tabs = [];
      this.tabsByName = new Hash();
      this.selected = null;
      $(group)
         .getElements(this.options.selector)
         .each(function (tab) {
            this.addTab(tab);
         }, this);
   },

   addTab: function (label) {
      let id = label.get(this.options.attribute).match(this.options.id)[1];
      let content = $(id);
      let tab = { id: id, label: label, content: content };
      this.tabs.push(tab);
      this.tabsByName.set(id, tab);

      label.addEvent(
         'click',
         function (e, tab) {
            e.stop();
            this.select(tab);
         }.bind(this, tab)
      );

      if (!this.selected && (this.options.active == undefined || id == this.options.active)) {
         this.select(tab);
      } else {
         content.hide();
      }
   },

   select: function (tab) {
      if (typeOf(tab) == 'string') {
         tab = this.tabsByName.get(tab);
      }
      if (this.selected) {
         if (this.selected == tab) {
            return false;
         }
         this.selected.label.removeClass('active');
         this.selected.content.hide();
      }
      tab.label.addClass('active');
      tab.content.show();
      this.selected = tab;
      return false;
   },

   getSelected: function () {
      return this.selected.content.get('id');
   },
}));

// eslint-disable-next-line no-var
export var ModalTabs = (window.ModalTabs = new Class({
   Extends: Tabs,

   select: function (tab) {
      if (typeOf(tab) == 'string') {
         tab = this.tabsByName.get(tab);
      }
      if (this.selected) {
         if (this.selected == tab) {
            return false;
         }

         this.selected.label.removeClass('active');
         this.selected.content.hide();

         this.fireEvent('on' + this.selected.id + 'Unload');

         tab.label.addClass('active');
         tab.content.show();
         this.selected = tab;
      } else {
         tab.label.addClass('active');
         tab.content.show();
         this.selected = tab;
      }
      return false;
   },
}));
