const tabsDropdownClass = '.core-control.core-tabs-dropdown';
const tabsDropdownsNodeList = document.querySelectorAll(tabsDropdownClass);
// Convert NodeList to an array for IE11
const tabsDropdowns = Array.prototype.slice.call(tabsDropdownsNodeList);

document.body.addEventListener('click', ev => {
   tabsDropdowns.forEach(tabsDropdown => {
      const clickedTab = ev.target.parentNode == tabsDropdown;
      const clickedTabOption = ev.target.parentNode.parentNode == tabsDropdown;

      if (clickedTab) {
         tabsDropdown.classList.toggle('opened');
         return;
      }

      if (clickedTabOption) {
         return;
      }

      tabsDropdown.classList.remove('opened');
   });
});
