import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as Actions from '../actions';
import BannerContainer from './components/banner_container';
import ActionButton, { mobileBreakpoint } from './components/action_button';
import DismissButton from './components/dismiss_button';
import { fontFamily } from '@dozuki/web-js/primitives';

const BodyContainer = styled.div`
   display: flex;
   flex: 1;
   justify-content: center;
   height: 100%;
`;

const Image = styled.img`
   min-height: 169px;
   min-width: auto;
   margin-right: 32px;
   border-radius: 4px;
   box-shadow: 0px 16px 36px rgba(16, 22, 26, 0.2), 0px 3px 10px rgba(16, 22, 26, 0.2);

   ${mobileBreakpoint} {
      display: none;
   }
`;

const InfoContainer = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: space-around;
   padding: 10px 0;
   max-width: 662px;
   text-align: left;
`;

const Title = styled.h3`
   font-family: ${fontFamily.lato};
   font-style: normal;
   font-weight: bold;
   line-height: normal;
   font-size: 24px;
   margin: 0;
   color: ${props => props.color};
`;

const Description = styled.h3`
   font-size: 14px;
   font-family: ${fontFamily.lato};
   font-style: normal;
   font-weight: normal;
   line-height: 21px;
   margin: 0;
   color: ${props => props.color};

   ${mobileBreakpoint} {
      margin-top: 16px;
   }
`;

const ActionContainer = styled.div`
   display: flex;
`;

const mapDispatchToProps = dispatch => ({
   popBanner: id => dispatch(Actions.popBanner(id)),
});

const LargeBanner = ({ bannerData, index, numBanners, popBanner }) => {
   const { id, title, description, action, theme, imgSrc } = bannerData;
   const { buttonText, buttonColor, buttonTextColor, url } = action;
   const { textColor, background } = theme;

   return (
      <BannerContainer index={index} isHidden={index < numBanners - 1} background={background}>
         <BodyContainer>
            {<Image src={imgSrc} role="presentation" />}
            <InfoContainer>
               <Title color={textColor}>{title}</Title>
               <Description color={textColor}>{description}</Description>
               <ActionContainer>
                  <ActionButton
                     textColor={buttonTextColor}
                     buttonColor={buttonColor}
                     href={url}
                     target="_blank"
                     rel="noreferrer"
                  >
                     {buttonText}
                  </ActionButton>
               </ActionContainer>
            </InfoContainer>
         </BodyContainer>
         <DismissButton color={textColor} onClick={() => popBanner(id)} />
      </BannerContainer>
   );
};

export default connect(null, mapDispatchToProps)(LargeBanner);
