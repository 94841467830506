import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as Actions from './actions';
import Banner from './banners';
import { getAnnouncementsToDisplay } from './build_announcements';

const Container = styled.div`
   display: ${props => (props.closed ? 'none' : 'flex')};
   min-height: ${props => props.height};
   background: white;
   animation: ${props => (props.isClosing ? 'slideOut' : 'slideIn')} 0.25s ease-in-out;

   @keyframes slideIn {
      0% {
         transform: translateY(-50%);
         margin-bottom: -${props => props.height};
         opacity: 0;
      }
   }

   @keyframes slideOut {
      100% {
         transform: translateY(-50%);
         margin-bottom: -${props => props.height};
         opacity: 0;
      }
   }
`;

const mapStateToProps = state => ({
   bannerState: state.bannerState,
});

const mapDispatchToProps = dispatch => ({
   pushBanner: banner => dispatch(Actions.pushBanner(banner)),
});

// The heights for each of the banner types.
const heights = {
   small: '50px',
   large: '200px',
   eu: '186px',
};

const animDuration = 250;

class Announcements extends Component {
   constructor(props) {
      super(props);

      this.containerRef = React.createRef();

      this.state = {
         isClosing: false,
         closed: false,
         bannerStack: props.bannerState.bannerStack,
      };
   }

   static getDerivedStateFromProps(nextProps, prevState) {
      const { bannerState } = nextProps;
      const { bannerStack } = bannerState;
      const isClosing = bannerStack.length === 0;

      return {
         isClosing,
         bannerStack: isClosing ? prevState.bannerStack : bannerStack,
      };
   }

   animateClosed() {
      setTimeout(() => {
         this.setState({
            isClosing: false,
            closed: true,
         });
      }, animDuration - 30);
   }

   componentDidUpdate() {
      const { isClosing, closed } = this.state;

      if (isClosing && !closed) {
         this.animateClosed();
      }
   }

   componentDidMount() {
      getAnnouncementsToDisplay().forEach(banner => this.props.pushBanner(banner));
   }

   render() {
      const { bannerStack, isClosing, closed } = this.state;

      if (bannerStack.length === 0) {
         return null;
      }

      const curBanner = bannerStack[bannerStack.length - 1];
      const curBannerHeight = heights[curBanner.type];

      setTimeout(() => {
         const mobileMenu = document.getElementById('mobileMenuContainer');
         const bannerHeight = this.containerRef.current.offsetHeight;

         if (mobileMenu) {
            mobileMenu.style.height = `calc(100vh - 60px - ${bannerHeight}px)`;
         }
      }, 0);

      return (
         <Container
            height={curBannerHeight}
            isClosing={isClosing}
            closed={closed}
            ref={this.containerRef}
         >
            {bannerStack.map((bannerData, index) => (
               <Banner
                  key={`banner-${index}`}
                  index={index}
                  numBanners={bannerStack.length}
                  bannerData={bannerData}
               />
            ))}
         </Container>
      );
   }
}

export default connect(mapStateToProps, mapDispatchToProps)(Announcements);
