import { PUSH_BANNER, POP_BANNER } from './constants';
import { getCookieName, DISMISSED_VALUE } from './build_announcements';
import { CookieUtils } from 'Shared/cookies/cookie-utils';

export const pushBanner = banner => ({ type: PUSH_BANNER(), banner });
export const popBanner = id => {
   return dispatch => {
      CookieUtils.set(getCookieName(id), DISMISSED_VALUE(), {
         maxAgeInSeconds: 2592000, // 30 days
      });
      dispatch({
         type: POP_BANNER(),
      });
   };
};
