import styled from 'styled-components';

const BannerContainer = styled.div`
   display: ${props => (props.isHidden ? 'none' : 'flex')};
   flex: 1;
   justify-content: space-between;
   align-items: center;
   padding: 16px;
   background: ${props => props.background};
`;

export default BannerContainer;
