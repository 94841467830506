import styled from 'styled-components';
import { constants as coreComponentsConstants } from 'core-components';
import { space, fontWeight } from '@dozuki/web-js/primitives';

const { shadows } = coreComponentsConstants;

export const DropdownContainer = styled.div`
   display: flex;
   flex-direction: column;
   background-color: ${({ theme }) => theme.background};
   box-shadow: ${shadows.dropShadow[2]};
`;

export const CardColumnContainer = styled.div`
   display: flex;
   justify-content: space-between;
   padding: ${({ theme }) => `${theme.dropdownTopBottomPadding} ${theme.dropdownSidePadding}`};
`;

export const CardColumn = styled.div`
   display: flex;
   flex-flow: column nowrap;
`;

export const CardLink = styled.div`
   padding: ${({ theme }) => theme.cardPadding} 0;
   display: flex;
   align-items: center;
   line-height: ${space[3]};
   font-weight: ${fontWeight.bold};
   white-space: nowrap;

   :hover {
      cursor: pointer;
   }
`;
