import * as React from 'react';
import styled from 'styled-components';
import { fontFamily } from '@dozuki/web-js/primitives';

export const mobileBreakpoint = '@media screen and (max-width: 650px)';

const Button = styled.a`
   display: flex;
   align-items: center;
   margin: ${props => props.margin || 0};
   box-sizing: border-box;
   text-decoration: none !important;
   border: none;
   border-radius: 4px;
   outline: 0;
   white-space: nowrap;
   cursor: pointer;
   user-select: none;
   -webkit-appearance: none;
   background-color: ${props => props.buttonColor};
   color: ${props => props.textColor};
   padding: 8px 12px;
   font-family: ${fontFamily.lato};
   font-style: normal;
   font-weight: bold;
   line-height: normal;
   font-size: 14px;
   text-align: center;
   box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;

   ${mobileBreakpoint} {
      margin-top: 16px;
   }

   :active {
      filter: brightness(95%);
   }

   :visited {
      color: ${props => props.textColor};
   }
`;

const Icon = styled.img`
   max-height: 24px;
   margin-right: 8px;
`;

const ActionButton = ({ buttonColor, textColor, icon, margin, children, href, onClick }) => {
   return (
      <Button
         href={href}
         onClick={onClick}
         buttonColor={buttonColor}
         textColor={textColor}
         margin={margin}
      >
         {icon && <Icon src={icon} />}
         {children}
      </Button>
   );
};

export default ActionButton;
