export const getAnnouncementsToDisplay = function () {
   App.bannerAnnouncements = App.bannerAnnouncements || [];

   return App.bannerAnnouncements.filter(announcement => {
      const bannerCookie = Cookie.read(getCookieName(announcement.id));
      return bannerCookie !== DISMISSED_VALUE();
   });
};

export const getCookieName = id => {
   return `banner-${id}-dismissed`;
};

export const DISMISSED_VALUE = () => '1';
