onDomReady(function () {
   $$('.js-status-cancel').each(function (el) {
      let notification = el.getParent(),
         container = notification.getParent(),
         id = notification.get('id'),
         clearNotification = function () {
            if (container.getChildren().length <= 1) {
               container.dispose();
            } else {
               notification.dispose();
            }
         };

      el.addEvent('click', function (ev) {
         clearNotification();
      });
   });
});
