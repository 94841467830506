import { FrameModules } from 'Shared/frame_modules';

FrameModules.add('UserFlagsFrameModule', function () {
   let setupLinkActions = function () {
      $$('.setUserFlagLink').each(function (link) {
         // Key we use in the DB.
         let contentKey = link.get('data-contentKey');

         // ID of the element to be removed.
         let contentId = link.get('data-contentId');

         link.addEvent('click', function (event) {
            event.stop();

            if (contentId !== null) {
               TweenMax.to($(contentId), 0.5, { height: 0, overflow: 'hidden' });
            }

            new Request.AjaxIO('setFlag', {
               onSuccess: function (response) {
                  if (!response.success) {
                     alert(response.error);
                  }
               },
            }).send(contentKey);
         });
      });
   };

   setupLinkActions();
});
