import { FrameModules } from 'Shared/frame_modules';

FrameModules.add('CommentsWatchFrameModule', function () {
   let on = $('commentsWatchOn');
   let off = $('commentsWatchOff');

   when(on, function (on) {
      on.getFirst('.js-toggle-watch').addEvent('click', function (ev) {
         ev.stop();
         new Request.AjaxIO('watch', {
            onSuccess: function () {
               on.hide();
               off.show('inline');
            },
         }).send('on', this.get('data-context'), this.get('data-contextid'));
      });
   });

   when(off, function (off) {
      off.getFirst('.js-toggle-watch').addEvent('click', function (ev) {
         ev.stop();
         new Request.AjaxIO('watch', {
            onSuccess: function () {
               off.hide();
               on.show('inline');
            },
         }).send('off', this.get('data-context'), this.get('data-contextid'));
      });
   });
});
