import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as Actions from '../actions';
import BannerContainer from './components/banner_container';
import ActionButton from './components/action_button';
import { getFlagIcon } from 'Shared/utils';
import { fontFamily } from '@dozuki/web-js/primitives';

export const mobileBreakpoint = '@media screen and (max-width: 650px)';

const InfoContainer = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: space-around;
   margin: 0 auto;
   padding: 10px 0;
   max-width: 950px;
   text-align: center;
`;

const Title = styled.h3`
   font-family: ${fontFamily.lato};
   font-style: normal;
   font-weight: bold;
   line-height: normal;
   font-size: 24px;
   margin: 0 0 8px 0;
   color: ${props => props.color};
`;

const Description = styled.h3`
   font-size: 16px;
   font-family: ${fontFamily.lato};
   font-style: normal;
   font-weight: normal;
   line-height: 21px;
   margin: 0 0 4px 0;
   color: ${props => props.color};

   ${mobileBreakpoint} {
      margin-top: 16px;
   }
`;

const ActionContainer = styled.div`
   display: flex;
   justify-content: center;
`;

const mapDispatchToProps = dispatch => ({
   popBanner: id => dispatch(Actions.popBanner(id)),
});

const EUBanner = ({ bannerData, index, numBanners, popBanner }) => {
   const { id, title, description, action, theme, onAction, onDismiss } = bannerData;
   const { buttonText, buttonColor, buttonTextColor, url, countryCode } = action;
   const { textColor, background } = theme;
   const euIcon = getFlagIcon(countryCode);
   const usIcon = getFlagIcon('US');

   const handleActionClick = () => {
      if (onAction) {
         onAction();
      }
   };

   const dismissBanner = () => {
      popBanner();
      if (onDismiss) {
         onDismiss();
      }
   };

   return (
      <BannerContainer index={index} isHidden={index < numBanners - 1} background={background}>
         <InfoContainer>
            <Title color={textColor}>{title}</Title>
            <Description color={textColor}>{description}</Description>
            <ActionContainer>
               <ActionButton
                  textColor={buttonTextColor}
                  buttonColor={buttonColor}
                  margin="0 24px 0 0"
                  icon={euIcon}
                  href={url}
                  onClick={handleActionClick}
                  target="_blank"
                  rel="noreferrer"
               >
                  {buttonText}
               </ActionButton>
               <ActionButton
                  textColor={buttonTextColor}
                  buttonColor={buttonColor}
                  onClick={dismissBanner}
                  icon={usIcon}
                  target="_blank"
                  rel="noreferrer"
               >
                  {_js('Buy from %1', 'US')}
               </ActionButton>
            </ActionContainer>
         </InfoContainer>
      </BannerContainer>
   );
};

export default connect(null, mapDispatchToProps)(EUBanner);
