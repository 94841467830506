import { BlurbFinder } from 'Shared/blurb_finder';

// eslint-disable-next-line no-var
export var SingleFieldEditor = (window.SingleFieldEditor = new Class({
   Extends: BlurbFinder,

   iconShowDisplayType: 'inline',

   initialize: function (el, url, options) {
      // Set up the edit control
      let a = el.getElement('a');
      let icon = new Element('span', {
         class: 'edit iconLink',
         title: options.field_title || _js('Edit'),
         styles: { display: 'none' },
         html: window.guide_constants.fa('PENCIL'),
      });

      let cancelIcon = new Element('span', {
         class: 'iconLink js-icon-cancel icon-cancel',
         styles: { display: 'none' },
         html: window.guide_constants.fa('TIMES'),
      });

      let textWidth = el.getDimensions().x + 20;
      el.adopt(icon);
      el.adopt(cancelIcon);

      let input = new Element('input', { styles: { width: options.width || textWidth } });

      let hideInput = function () {
         input.hide();
         a.show();
         icon.show(this.iconShowDisplayType);
         cancelIcon.hide();
         this.hideChoices();
         this.fireEvent('editEnd');
      }.bind(this);

      this.addEvent('submit', function (opt) {
         new Request.AjaxIO('setSingleField', {
            onSuccess: function (response) {
               if (response) {
                  el.set('data-single-field', response.field);
                  a.set('text', response.text);
               }
               hideInput();
            },
         }).send(options.field, input.value, opt);
      });

      // The submit event
      input
         .addEvents({
            keydown: function (ev) {
               if (ev.key == 'esc') {
                  ev.stop();
                  hideInput();
                  return;
               }
            }.bind(this),
            keypress: function (ev) {
               if (ev.key != 'enter') {
                  return;
               }
               ev.stop();
               this.fireEvent('submit');
            }.bind(this),
         })
         .hide()
         .inject(a, 'after');

      // The edit event
      icon.addEvent(
         'click',
         function (ev) {
            ev.stop();
            a.hide();
            icon.hide();
            cancelIcon.show(this.iconShowDisplayType);

            input.set('value', el.get('data-single-field')).show('inline-block').focus();
            input.selectRange(0, input.value.length);
            this.fireEvent('editBegin');
         }.bind(this)
      );

      cancelIcon.addEvent('click', function (ev) {
         ev.stop();
         hideInput();
      });

      if (options.hideUntilHover) {
         if (!input.isVisible()) {
            icon.setStyle('visibility', 'hidden').show(this.iconShowDisplayType);
         }
         el.addEvents({
            mouseenter: function (ev) {
               if (!input.isVisible()) {
                  icon.setStyle('visibility', 'visible');
               }
            }.bind(this),
            mouseleave: function (ev) {
               if (!input.isVisible()) {
                  icon.setStyle('visibility', 'hidden');
               }
            }.bind(this),
         });
      } else {
         icon.show(this.iconShowDisplayType);
      }

      // Initialize the autocompleter
      if (url) {
         this.parent(input, url, options);
      }
   },
}));
