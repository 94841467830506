onDomReady(makeTextareasResizable);

export { makeTextareasResizable };
window.makeTextareasResizable = makeTextareasResizable;
function makeTextareasResizable() {
   $$('textarea').each(function (textarea) {
      let offset = !window.opera
         ? textarea.offsetHeight - textarea.clientHeight
         : textarea.offsetHeight +
           parseInt(window.getComputedStyle(textarea, null).getPropertyValue('border-top-width'));
      let textareaMaxHeight = 300;
      let textareaInitialHeight = textarea.getSize().y;

      let resize = function (textarea) {
         textarea.style.height = textareaInitialHeight;
         textarea.style.overflow = 'auto';

         if (
            textarea.scrollHeight >= textareaInitialHeight &&
            textarea.scrollHeight < textareaMaxHeight
         ) {
            textarea.style.height = textarea.scrollHeight + offset + 'px';
         } else if (textarea.scrollHeight > textareaMaxHeight) {
            textarea.style.height = textareaMaxHeight + 'px';
         }
      };

      textarea.addEventListener &&
         textarea.addEventListener('input', function (event) {
            resize(textarea);
         });

      textarea.addEventListener &&
         textarea.addEventListener('mouseup', function (event) {
            if (textarea.getSize().y !== textareaInitialHeight) {
               textareaInitialHeight = textareaMaxHeight = textarea.getSize().y;
            }
         });
   });
}
