import { RangeSlider } from 'Shared/range_slider';
import { FrameModules } from 'Shared/frame_modules';
import LoadingIndicator from 'Shared/loading_indicator';

FrameModules.add('PatrolThresholdFrameModule', function () {
   when($('patrolThreshold'), function (form) {
      let save = $E('#patrolThreshold button');
      let input = form.getElement('input.value');

      let type = form.type.value;
      // The maximum value the user is allowed to set
      let max_allowed = form.max_allowed.value.toInt();
      // The maximum value anyone can set
      let max_displayed = form.max_displayed.value.toInt();
      // The value that gets set when "infinite" is submitted
      let infinite = form.infinite.value.toInt();

      let infChar = '∞';
      let initial_value = input.value === infChar ? infinite : input.value.toInt();

      let slider = new RangeSlider($('patrolSlider'), {
         type: 'single',
         range: [0, max_allowed],
         position: [initial_value],
         onChange: function (step) {
            input.value = step === max_displayed ? infChar : step;
         }.bind(this),
         onComplete: function (step) {
            save.disabled = false;
         }.bind(this),
      });

      input.addEvent('focus', function () {
         save.disabled = false;
      });

      form.addEvent('submit', function (ev) {
         ev.stop();

         let docid = this.docid.value;
         let threshold = input.value === infChar ? 'inf' : input.value;

         let request = new Request.AjaxIO('saveThreshold').send(type, docid, threshold);
         request.then(
            function (response) {
               this.submitButton.blur();
               input.blur();

               if (response == 'inf' || response >= max_allowed) {
                  slider.set(max_allowed);
                  input.value = infChar;
               } else {
                  slider.set(response);
                  input.value = response;
               }

               save.disabled = true;
            }.bind(this)
         );

         LoadingIndicator.withPromise(request);
      });

      save.disabled = true;
   });
});
