import { Modal } from 'Shared/modal';

onDomReady(function () {
   $$('.keyboard-shortcuts').addEvent('click', function () {
      Modal.open({
         type: 'element',
         element: $('shortcuts-modal'),
      });
   });
});
