import { Tooltip } from 'Shared/tooltips';
import { MediaManager } from 'Shared/FrameModules/MediaLibrary/media_manager';
import { MinimalMediaTarget } from 'Shared/FrameModules/MediaLibrary/minimal_target';
import { FormLibrary } from 'Shared/utils';
import { Modal } from 'Shared/modal';
import trackInMatomoAndGA from 'Shared/Analytics/CombinedGAMatomo';

// eslint-disable-next-line no-var
export var WikiTextEditor = (window.WikiTextEditor = new Class({
   Implements: [Options],

   options: {
      toolbarItems: ['heading', 'bold', 'italic', 'underline', 'ul', 'ol', 'link'],
      className: 'wikiTextEditorToolbar',
      helpLink: null,
      helpText: null,
      listHelper: true,
      tooltipOptions: {
         side: 'top',
         align: 'left',
         autoshow: 'true',
      },
   },

   textarea: null,
   toolbar: null,
   bindings: {},
   selection: null,

   initialize: function (textarea, list, options) {
      this.setOptions(options);
      this.textarea = $(textarea);

      if (!list) {
         let prev = this.textarea.getPrevious();
         let classes = this.options.className.split(' ');
         if (prev && prev.hasClass(classes[0])) {
            this.toolbar = prev;
         } else {
            this.toolbar = new Element('ul', { class: this.options.className });
            this.toolbar.inject(this.textarea, 'before');
         }
      } else {
         this.toolbar = $(list);
      }

      this.buildToolbar();

      this.installKeyListener();

      this.installClipboardListener();

      if (this.options.listHelper) {
         this.installListHelper();
      }
   },

   buildToolbar: function () {
      if (this.options.helpLink) {
         this.toolbar.adopt(
            new Element('li', {
               class: 'toolbarHelp',
            }).adopt(
               new Element('a', {
                  href: this.options.helpLink,
                  class: 'muted',
                  alt: this.options.helpText || _js('Help'),
                  title: this.options.helpText || _js('Help'),
                  target: '_BLANK',
               }).adopt(
                  new Element('i', {
                     class: 'fa fa-question-circle',
                  })
               )
            )
         );
      }

      this.options.toolbarItems.each(
         function (item) {
            item = this.toolbarItems[item];
            let type = item[0].capitalize(); // e.g. Heading
            let builder = 'add' + type + 'Item'; // e.g. addHeadingItem
            this[builder].apply(this, Array.convert(item.slice(1))); // pass all but type
         }.bind(this)
      );
   },

   toolbarItems: {
      heading: ['heading', 2, _js('Make heading')],

      bold: ['wrapper', 'Bold', '***', _js('bold text'), _js('Make text bold'), 'C-b'],

      italic: ['wrapper', 'Italic', "''", _js('italic text'), _js('Make text italic'), 'C-i'],

      underline: [
         'wrapper',
         'Underline',
         '++',
         _js('Underlined text'),
         _js('Make text underlined'),
         'C-u',
      ],

      ul: ['list', 'List', '*', _js('Make list'), 'C-2'],

      ol: ['list', 'Numbered list', '#', _js('Make numbered list'), 'C-3'],

      link: ['link', 'C-l'],

      media: ['media'],

      image: ['image'],
      video: ['video'],
   },

   addHeadingItem: function (level, title) {
      let id = this.makeItemId('Heading' + level);
      let name = _js('Heading') + ' ' + level;
      let wrapper = '';
      for (let i = 0; i < level; i++) {
         wrapper += '=';
      }
      this.addFunction(
         name,
         function () {
            this.wrapSelection({
               before: wrapper + ' ',
               defaultMiddle: _js('Heading text'),
               after: ' ' + wrapper,
            });
         },
         {
            class: id,
            icon: 'header',
            tooltip: title,
         }
      );
   },

   addWrapperItem: function (name, wrapper, defaultText, title, binding) {
      let id = this.makeItemId(name);
      this.addFunction(
         name,
         function () {
            this.wrapSelection({
               before: wrapper,
               defaultMiddle: defaultText,
               after: wrapper,
            });
            if (binding) {
               return false;
            }
         },
         {
            class: id,
            icon: name.toLowerCase(),
            tooltip: title,
         },
         binding
      );
   },

   addListItem: function (name, bullet, title, binding) {
      let id = this.makeItemId(name);
      let explain = _js(
         'Hit <enter> to insert another list item, and <enter> twice in a row to end the list. To get subitems, repeat the bullet character once for each level of indent you want (e.g. %1%1)',
         '<bullet>'
      );
      let defaultText = '<bullet> ' + explain;
      this.addFunction(
         name,
         function () {
            this.replaceMultilineSelection({
               eachLine: function (line) {
                  return bullet + ' ' + line;
               },
               defaultText: {
                  text: defaultText.replace(/<bullet>/g, bullet),
                  select: explain.replace(/<bullet>/g, bullet),
               },
            });
            if (binding) {
               return false;
            }
         },
         {
            class: id,
            icon: bullet === '*' ? 'list-ul' : 'list-ol',
            tooltip: title,
         },
         binding
      );
   },

   addLinkItem: function () {
      this.addFunction(
         _js('Link'),
         function () {
            let selection = this.textarea.getSelectedText();
            let defaultUrl = '';
            let defaultLinkText = '';

            if (selection !== '') {
               if (selection.test(/^https?:\/\//)) {
                  defaultUrl = selection;
               } else if (selection.test(/^([^.\s\/]+\.)+[^.\s\/]{2,7}/)) {
                  // Try to be forgiving of things that look like links, but lack
                  // a protocol.
                  defaultUrl = 'http://' + selection;
               } else {
                  defaultLinkText = selection;
               }
            }

            Modal.open({
               type: 'form',
               defaultWidth: true,
               form: FormLibrary.create({
                  id: 'wikiTextEditorLinkForm',
                  class: 'wikiTextEditorModal',
                  fields: {
                     url: {
                        label: _js('Link URL'),
                        type: 'text',
                        value: defaultUrl,
                        properties: { id: 'wikiTextEditorURLEntry' },
                     },
                     linkText: {
                        label: _js('Link Text (optional)'),
                        type: 'text',
                        value: defaultLinkText,
                        properties: { id: 'wikiTextEditorLinkTextEntry' },
                     },
                  },
                  submit: _js('Insert link'),
               }),
               onSubmit: function (form) {
                  let linkText = form.linkText ? '|' + form.linkText.replaceAll('|', '') : '';
                  let url = form.url;
                  if (!/https?:\/\//.test(url)) {
                     url = 'http://' + url;
                  }
                  // We know that this is supposed to be text free of wiki syntax,
                  // so we're free to encode it.
                  url = this.encodeWikiSyntax(url);
                  url = url.replace(/%7Cnew_window=true\s*/, '|new_window=true');
                  this.setStoredSelection();
                  this.insertAtCursor('[link|' + url + linkText + ']');
               }.bind(this),
               onLoaded: function () {
                  if (defaultUrl) {
                     $('wikiTextEditorLinkTextEntry').focus();
                  }
               },
            });

            let cancelButton = new Element('button', {
               type: 'button',
               class: 'button cancel-link',
               text: _js('Cancel'),
            }).addEvent('click', Modal.cancel);

            $$('.wikiTextEditorModal .formBody').adopt(cancelButton);
         },
         {
            class: this.makeItemId('Link'),
            icon: 'link',
            tooltip: _js('Insert a link'),
         }
      );
   },

   addVideoItem: function () {
      this.addFunction(
         _js('Video'),
         function () {
            let selection = this.textarea.getSelectedText();

            let beforeTag = '[video|';

            if (selection !== '') {
               if (selection.test(/^https?:\/\//)) {
                  this.replaceSelectionWithTemplate({
                     before: beforeTag,
                     select: selection + ']' + _js('Video caption'),
                     after: '[/video]',
                  });
               } else if (selection.test(/^([^.\s\/]+\.)+[^.\s\/]{2,7}/)) {
                  // Try to be forgiving of things that look like links, but lack
                  // a protocol.
                  this.replaceSelectionWithTemplate({
                     before: beforeTag,
                     select: selection + ']' + _js('Video caption'),
                     after: '[/video]',
                  });
               } else {
                  this.replaceSelectionWithTemplate({
                     before: beforeTag,
                     select: '{' + _js('Insert Video URL Here') + '}',
                     after: ']',
                  });
               }
               return;
            }

            Modal.open({
               type: 'form',
               defaultWidth: true,
               form: FormLibrary.create({
                  id: 'wikiTextEditorVideoForm',
                  class: 'wikiTextEditorModal',
                  fields: {
                     videoUrl: {
                        label: _js('YouTube or Vimeo Video URL'),
                        type: 'text',
                        properties: { id: 'wikiTextEditorVideoURLEntry' },
                     },
                     videoCaption: {
                        label: _js('Video Caption (optional)'),
                        type: 'text',
                        properties: { id: 'wikiTextEditorVideoTextEntry' },
                     },
                  },
                  submit: _js('Insert video'),
               }),
               onSubmit: function (form) {
                  let caption = form.videoCaption ? ']' + form.videoCaption + '[/video]' : '';
                  // Set the selection stored when the field lost focus.
                  this.setStoredSelection();
                  if (!/https?:\/\//.test(form.videoUrl)) {
                     form.videoUrl = 'http://' + form.videoUrl;
                  }

                  this.insertAtCursor('[video|' + form.videoUrl + caption + ']');
               }.bind(this),
            });

            let cancelButton = new Element('button', {
               type: 'button',
               class: 'button cancel-link',
               text: _js('Cancel'),
            }).addEvent('click', Modal.cancel);

            $$('.wikiTextEditorModal .formBody').adopt(cancelButton);
         },
         {
            class: this.makeItemId('Video'),
            icon: 'video-camera',
            tooltip: _js('Insert a Youtube or Vimeo video'),
         }
      );
   },

   addImageItem: function () {
      this.addFunction(_js('Image'), function () {}, {
         class: this.makeItemId('Image'),
         icon: 'image',
         tooltip: _js('Insert an image'),
      });
   },

   addMediaItem: function () {
      let editor = this;
      this.mediaTarget = new MinimalMediaTarget({
         customAttach: function (mediaItem, finished) {
            if (mediaItem) {
               // Mootools insertAtCursor() function fails (does nothing) when
               // the field is blank and unfocused... yay. Focusing first makes
               // it work.
               editor.textarea.focus();
               editor.insertAtCursor(mediaItem.toWikiText(), false);
            }
            // finished(oldItem, successfully attached?, error message)
            finished(null, true);
         },
         accepts: 'wiki_media',
      });
      this.addFunction(
         _js('Media'),
         function () {
            MediaManager.browse(editor.mediaTarget);
         },
         {
            class: this.makeItemId('Media'),
            icon: 'image',
            tooltip: _js('Insert an image or video'),
         }
      );
   },

   addFunction: function (name, callback, properties, binding) {
      let item = new Element('li');
      let anchor = name.replace(/[^ a-zA-Z0-9-_]/g, '').replace(/\s+/, '-');
      let itemlink = new Element('a', {
         events: {
            mousedown: function (ev) {
               // Store the current selection so that we can restore it again
               // later.  This is necessary because IE loses the selection
               // information when the field is blurred.
               this.selection = this.textarea.getSelectedRange();
            }.bind(this),
            click: function (ev) {
               ev.stop();
               // Set the selection from what we stored on mousedown.
               this.setStoredSelection();
               callback.apply(this, Array.convert(null));
               trackInMatomoAndGA({
                  eventCategory: 'Wiki Text Editor',
                  eventAction: 'Toolbar Item Clicked',
                  eventName: name,
               });
            }.bind(this),
         },
         href: '#' + anchor.toLowerCase(),
         // Set the tab order so that these come up last.
         tabindex: 1000,
      });
      itemlink.adopt(new Element('span', { text: name }));
      itemlink.adopt(new Element('i', { class: 'fa fa-' + properties.icon }));
      itemlink.setProperties(properties || {});
      itemlink.inject(item, 'bottom');
      item.inject(this.toolbar);

      Tooltip.instance(itemlink, properties.tooltip, this.options.tooltipOptions);

      if (binding) {
         this.addBinding(binding, callback);
      }
   },

   makeItemId: function (name) {
      name = name.replace(/\s+/g, '-').camelCase().capitalize();
      return 'wikiTextEditor' + name + 'Item';
   },

   setStoredSelection: function () {
      if (this.selection) {
         this.textarea.selectRange(this.selection.start, this.selection.end);
      }
   },

   insertAtCursor: function (text, select) {
      this.textarea.insertAtCursor(text, Array.pick([select, false]));
   },

   wrapSelection: function (options) {
      let selection = this.textarea.getSelectedText();
      if (selection !== '') {
         let text = options.before + selection + options.after;
         this.textarea.insertAtCursor(text, false);
      } else {
         this.textarea.insertAroundCursor(options);
      }
   },

   replaceMultilineSelection: function (options) {
      let selection = this.textarea.getSelectedText();
      if (selection !== '') {
         let callback = options.eachLine;
         let lines = selection.split('\n');
         let newlines = [];
         lines.each(function (line) {
            if (line !== '') {
               newlines.push(callback.apply(this, Array.convert(line)));
            }
         }, this);
         this.textarea.insertAtCursor(newlines.join('\n'), false);
      } else {
         let start = this.textarea.getSelectionStart();
         let text = options.defaultText.text;
         this.textarea.insertAtCursor(text, false);
         if (options.defaultText.select) {
            let selectText = options.defaultText.select;
            let offset = text.indexOf(selectText);
            if (offset != -1) {
               start += offset;
               this.textarea.selectRange(start, start + selectText.length);
            }
         }
      }
   },

   replaceSelectionWithTemplate: function (options) {
      options = Object.merge(
         {
            before: '',
            select: '',
            after: '',
         },
         options
      );

      this.textarea.insertAtCursor(options.before, false);
      this.textarea.insertAroundCursor({
         before: '',
         defaultMiddle: options.select,
         after: options.after,
      });
   },

   installListHelper: function () {
      let listBullets = Hash.getValues(this.toolbarItems)
         .map(function (item) {
            return item[0] == 'list' ? item[2] : null;
         })
         .clean()
         .join('');
      let listItemRegex = new RegExp('^([' + listBullets + ']+)(.*)$');
      let emptyListRegex = new RegExp('^[\\s' + listBullets + ']*$');
      this.addBinding('enter', function () {
         let startLen = this.textarea.getSelectionStart();
         let endLen = this.textarea.getSelectionEnd();
         let lines = this.textarea.value.substring(0, startLen).split('\n');
         let currLine = lines.pop();
         let groups = currLine.match(listItemRegex);
         if (groups) {
            if (/^\s*$/.test(groups[2])) {
               // We're ending the list, and we need to delete the last line.
               let before = lines.join('\n') + '\n\n';
               if (before.test(emptyListRegex)) {
                  before = '';
               }
               this.textarea.selectRange(0, endLen);
               this.textarea.insertAtCursor(before, false);
               this.textarea.setCaretPosition(before.length + 1);
            } else {
               // We're extending the list.
               let newText = '\n' + groups[1] + ' ';
               this.textarea.insertAtCursor(newText, false);
               this.textarea.setCaretPosition(startLen + newText.length);
            }
            // Stop the event (but not other bindings).
            return false;
         }
      });
   },

   installKeyListener: function () {
      this.textarea.addEvent(
         'keypress',
         function (ev) {
            // Construct a string code from the keys pressed.
            let keys = [];
            ['shift', 'control', 'alt', 'meta'].each(function (modifier) {
               if (ev[modifier]) {
                  keys.push(modifier.capitalize().substring(0, 1));
               }
            });
            keys.push(ev.key);
            let code = keys.join('-');
            let passEvent = true;
            if (this.bindings[code]) {
               this.bindings[code].each(function (callback) {
                  if (callback.apply(this, Array.convert(ev)) === false) {
                     passEvent = false;
                  }
               }, this);
               if (!passEvent) {
                  ev.stop();
               }
            }
         }.bind(this)
      );
   },

   /**
    * Listens for paste events on the wiki input text area and intercepts
    * anything that looks like a URI (and nothing but a URI), replacing it with
    * an equivalent URI with wiki syntax (square brackets and vertical bar)
    * encoded. We can't do the same transformation across arbitrary text
    * without solving the general problem of identifying the boundaries of URIs
    * in running text.
    */
   installClipboardListener: function () {
      this.textarea.addEventListener(
         'paste',
         function (ev) {
            let text;
            if (window.clipboardData && window.clipboardData.getData) {
               // IE
               text = window.clipboardData.getData('Text');
               ev = window.event;
            } else if (ev.clipboardData && ev.clipboardData.getData) {
               text = ev.clipboardData.getData('text/plain');
            }
            if (!text) {
               return;
            }
            if (text.test(/^https?:\/\/[^\s]+$/)) {
               if (!ev.preventDefault) {
                  ev.returnValue = false;
               } else {
                  ev.preventDefault();
               }
               let url = this.encodeWikiSyntax(text);
               this.textarea.insertAtCursor(url, /* select */ false);
            }
         }.bind(this)
      );
   },

   addBinding: function (code, callback) {
      if (this.bindings[code]) {
         this.bindings[code].push(callback);
      } else {
         this.bindings[code] = [callback];
      }
   },

   /**
    * Replaces the special wiki syntax characters '[', ']', and '|' with their
    * URI-encoded equivalents. This allows users to enter URIs containing these
    * characters (unencoded).
    */
   encodeWikiSyntax: function (text) {
      return text.replace(/[[|\]]/g, function (matches) {
         return encodeURIComponent(matches[0]);
      });
   },
}));
