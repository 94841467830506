import * as React from 'react';
import styled from 'styled-components';
import Cross from '@core-ds/icons/16/Cross';
import { fontFamily } from '@dozuki/web-js/primitives';
import { withBreakpoints } from 'Shared/core-components/components/Breakpoints/breakpoints';

const Container = styled.div`
   display: flex;
   justify-content: flex-end;
   align-items: center;
   align-self: flex-start;
   margin-top: 4px;
   cursor: pointer;
`;

const Text = withBreakpoints(styled.h3`
   font-family: ${fontFamily.lato};
   font-style: normal;
   font-weight: bold;
   line-height: normal;
   font-size: 14px;
   text-align: right;
   margin: 0 4px 0 0;
   color: ${props => props.color};
   ${props => (props.hideTextOnMobile && props.breakpoints.isMobile() ? 'display: none;' : '')}
`);

const DismissButton = ({ color, onClick, hideTextOnMobile }) => {
   return (
      <Container onClick={onClick}>
         <Text hideTextOnMobile={hideTextOnMobile} color={color}>
            {_js('Dismiss')}
         </Text>
         <Cross name="x" color={color} size={16} />
      </Container>
   );
};

export default DismissButton;
