import { Utils } from 'Shared/utils';
import { Modal } from 'Shared/modal';
import { Button } from 'Shared/button-toggle';
import { TeamFinder } from 'Shared/team_finder';
import { UserFinder } from 'Shared/user_finder';
import { FrameModules } from 'Shared/frame_modules';

FrameModules.add('GuidePublishFrameModule', function () {
   when($('sidebarGuidePublish'), function (el) {
      new GuidePublish();
   });
});

// eslint-disable-next-line no-var
export var GuidePublish = (window.GuidePublish = new Class({
   ajaxSpinner: new Element('img', {
      src: window.shared_constants.BaseURI('SITE_IMAGE_AJAX_LOAD'),
   }),

   initialize: function () {
      let self = this;
      this.form = document.id('sidebarGuidePublish');
      this.guideid = this.form.getProperty('data-guideid');

      this.guideTeamList = document.id('guideTeamList');
      this.teamListField = document.id('teamListField');

      this.guideUserList = document.id('guideUserList');
      this.userListField = document.id('userListField');
      this.userSearchInput = document.id('searchUsers');
      this.teamSearchInput = document.id('searchTeams');
      this.userSearchSpinner = document.id('userSearchSpinner');
      this.teamSearchSpinner = document.id('teamSearchSpinner');
      this.userListMessage = document.id('userListMessage');

      if (!App.noAvatarUrl) {
         console.error('App.noAvatarUrl is not set.');
      }

      this.userFinder = new UserFinder(this.userSearchInput, {
         indicator: this.userSearchSpinner,
         showReputation: !this.form.hasClass('noReputation'),
         onSelection: function (el, selected, selection) {
            if (typeof el !== 'object') {
               return;
            }

            let user = el.retrieve('user');
            let guideid = self.guideid;
            new Request.API_2_0('guides/' + guideid + '/users/' + user.userid, {
               method: 'put',
               statusPanelMessage: _js('Adding user to guide...'),
               onSuccess: function (response) {
                  self.renderUserList(response.users);
               },
            }).send();
         },
      });

      when(this.teamSearchInput, input => {
         new TeamFinder(input, {
            indicator: this.teamSearchSpinner,
            onSelection: function (el) {
               if (typeof el !== 'object') {
                  return;
               }

               let team = el.retrieve('team');
               self.addTeam(team.teamid);
            },
         });
      });

      this.publishToggle = new Button.Toggle('publishToggle', {
         callback: this.checkPrereqs.bind(this),
      });

      if (this.teamListField) {
         $$('.removeTeam').addEvent('click', this.removeTeam.bind(this));
      }

      if (this.userListField) {
         this.guideUserList.addEvent('click:relay(.removeUser)', function (ev, link) {
            ev.stop();
            let userid = link.getProperty('data-userid');
            let guideid = self.guideid;
            new Request.API_2_0('guides/' + guideid + '/users/' + userid, {
               method: 'delete',
               statusPanelMessage: _js('Removing user from guide...'),
               onSuccess: function (response) {
                  self.renderUserList(response.users);
               },
            }).send();
         });
      }
   },

   checkPrereqs: function (ev) {
      let el = ev.target.get('data-value') ? ev.target : ev.target.getParent('[data-value]');
      let isPrivate = el.get('data-value') === 'private';
      let prereqs = App.prereqs;

      if (isPrivate && prereqs.length) {
         this.showConfirmPrivateModal(ev);
      } else {
         this.publish(ev);
      }
   },

   showConfirmPrivateModal: function (ev) {
      let template = window.guideHbsTemplates('GUIDE_EDIT_CONFIRM_PRIVATE_MODAL_HBS');
      let self = this;
      let title =
         App.prereqs.length === 1
            ? _js(
                 'Are you sure you want to make this guide private? The following guide will no longer be translatable:'
              )
            : _js(
                 'Are you sure you want to make this guide private? The following guides will no longer be translatable:'
              );

      let modalContent = template({
         prereqs: App.prereqs,
         description: title,
      });

      Modal.open({
         type: 'element',
         element: modalContent,
         onCancel: function () {
            self.publishToggle.untoggle();
         },
      });

      $$('.js-confirm-private-box .js-cancel-button').addEvent('click', Modal.cancel);
      $$('.js-confirm-private-box .js-confirm-private-button').addEvent('click', function () {
         Modal.close();
         self.publish(ev);
      });
   },

   renderUserList: function (users) {
      this.guideUserList.empty();
      if (users.length) {
         this.userListMessage.removeClass('hidden');
      } else {
         this.userListMessage.addClass('hidden');
      }
      users.each(
         function (user) {
            let container = new Element('li');
            let url;
            if (user.image && user.image.mini) {
               url = user.image.mini;
            } else {
               url = App.noAvatarUrl;
            }
            new Element('img', { src: url, class: 'pull-left' }).inject(container);

            let userDetails = new Element('div', { class: 'userDetails' }).inject(container);

            let h4 = new Element('h4').inject(userDetails);
            new Element('a', { href: user.url, text: user.username }).inject(h4);

            new Element('p', {
               text: user.reputation + ' ' + _js('reputation'),
               class: 'reputation',
            }).inject(userDetails);

            let removeUser = new Element('a', {
               'data-userid': user.userid,
               class: 'removeUser',
            }).inject(userDetails);
            new Element('i', { class: 'fa fa-times' }).inject(removeUser);

            new Element('div', { class: 'clearer' }).inject(container);
            container.inject(this.guideUserList);
         }.bind(this)
      );
   },

   buildAjaxSpinner: function (elClass) {
      let element = new Element('span', {
         class: 'ajax-spinner ' + elClass,
      });

      return element.adopt(this.ajaxSpinner);
   },

   publish: function (ev) {
      let el = ev.target.get('data-value') ? ev.target : ev.target.getParent('[data-value]'),
         self = this,
         isPublic = el.get('data-value') == 'public',
         revisionEl = $$('[name=intro[revisionid]]').pick(),
         revisionid = revisionEl === null ? App.guide_revisionid : revisionEl.get('value');

      $('publishField').adopt(self.buildAjaxSpinner('publish'));

      let url = 'guides/' + self.guideid + '/public?revisionid=' + revisionid;
      new Request.API_2_0(url, {
         method: isPublic ? 'put' : 'delete',
         onFailure: function (xhr) {
            let response = JSON.parse(xhr.response);
            $$('.ajax-spinner').dispose();
            let msg = _js('An unexpected error occurred.');
            if (response && response.message) {
               msg = response.message;
            }

            if (xhr.status === 404) {
               msg = _js('Guide not found. Try reloading the page.');
            } else if (xhr.status === 409) {
               msg = _js(
                  "Sorry, someone saved a new version of the introduction while you were editing it, so we couldn't save your changes. Reload the pages to load in the latest changes."
               );
            }

            $('guidePublishErrors').grab(Utils.createAlert('error', msg), 'top');
            self.publishToggle.untoggle();
         },
         onSuccess: function (response) {
            $$('.ajax-spinner').dispose();

            if (revisionEl !== null) {
               revisionEl.set('value', response.revisionid);
            }

            // Update revisionids for any subsequent requests.
            App.guide_revisionid = response.revisionid;
            if (App.guideApi) {
               App.guideApi.revisionid = App.guide_revisionid;
            }

            if (self.teamListField) {
               // Apparently, 'public' is reserved as a future keyword,
               // so we should refer to it like this.
               // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Lexical_grammar
               if (response['public']) {
                  self.teamListField.hide();
                  self.userListField.hide();
               } else {
                  self.teamListField.show();
                  self.userListField.show();
               }
            }
         },
      }).send(self.guideid, isPublic);
   },
   removeTeam: function (ev) {
      ev.stop();

      let el = ev.target.getParent('a');
      let parent = el.getParent('li');
      let teamid = el.getProperty('data-teamid');
      let self = this;

      // TODO: If the user is attempting to delete their own team, warn them first
      new Request.AjaxIO('removeGuideTeam', {
         onSuccess: function (response) {
            $$('.ajax-spinner').hide();

            // Delete successful
            if (response.success) {
               // Force refresh if user deleted own team
               if (response.forceRefresh) {
                  window.location.reload(true);
               }

               TweenMax.to(parent, 0.3, {
                  height: 0,
                  overflow: 'hidden',
                  onComplete: function () {
                     // Remove the element from the list
                     parent.dispose();

                     if (self.guideTeamList.getChildren('li').length === 0) {
                        self.toggleDefaultText('noTeams');
                     }
                  },
               });
            } else {
               // Notify user that delete failed
               parent.inject(
                  new Element('p', {
                     html: _js('Error deleting team'),
                     class: 'error',
                  })
               );
            }
         },
      }).send(this.guideid, teamid);
   },
   addTeam: function (teamid) {
      let self = this;

      new Request.AjaxIO('addGuideTeam', {
         onSuccess: function (response) {
            let guideTeamList = $('guideTeamList');

            guideTeamList.set('html', response.html);

            guideTeamList.getElements('.removeTeam').addEvent('click', self.removeTeam.bind(self));

            self.toggleDefaultText('yesTeams');

            $$('.ajax-spinner').hide();
         },
      }).send(this.guideid, [teamid]);
   },
   toggleDefaultText: function (version) {
      this.teamListField.getElements('.defaultText').addClass('hidden');
      this.teamListField.getElements('.defaultText.' + version).removeClass('hidden');
   },
}));
