import { FrameModules } from 'Shared/frame_modules';
import { PeriodicReviewEditScheduleModal } from 'Guide/periodic_review_edit_modal';

FrameModules.add('PeriodicReviewEditFrameModule', function () {
   new PeriodicReviewEdit();
});

// eslint-disable-next-line no-var
export var PeriodicReviewEdit = (window.PeriodicReviewEdit = new Class({
   initialize: function () {
      this.form = document.id('sidebarPeriodicReviewEdit');
      this.editButton = document.getElementById('edit-periodic-review-button');
      this.reviewScheduleModal = new PeriodicReviewEditScheduleModal();
      this.editButton.addEventListener('click', ev => {
         ev.preventDefault();
         this.reviewScheduleModal.open();
      });
   },
}));
