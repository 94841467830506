import { DeviceFinder } from 'Guide/device_finder';
import { FrameModules } from 'Shared/frame_modules';
import { CategoryCard } from 'Guide/category_card';

FrameModules.add('WikiRelatedFrameModule', function () {
   WikiRelatedFrameModule.initialize();
});

// eslint-disable-next-line no-var
export var WikiRelatedFrameModule = (window.WikiRelatedFrameModule = {
   initialize: function () {
      when($('wikiRelatedInput'), function (el) {
         let catCard = new CategoryCard(el);
         let deviceText = el.get('value').trim();

         if (deviceText) {
            catCard.updateDeviceInfo(deviceText);
            catCard.toggleDeviceBlurb(true);
         }

         DeviceFinder.initialize(el, {
            onHide: function () {
               catCard.updateDeviceInfo(this.element.value);
            },
            onSelection: function () {
               catCard.toggleDeviceBlurb(true);
            },
         });
      });
   },
});
