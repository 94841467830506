import { FrameModules } from 'Shared/frame_modules';
import { CategoryCard } from 'Guide/category_card';
import { DeviceFinder } from 'Guide/device_finder';

FrameModules.add('TopicHierarchyFrameModule', function () {
   TopicHierarchy.initialize();
});

// eslint-disable-next-line no-var
export var TopicHierarchy = (window.TopicHierarchy = {
   initialize: function () {
      when($('topicParentForm'), function (form) {
         let wikiid = $('th_wikiid').get('value').toInt();
         let areaInput = $('th_parentTitle');
         let msg = form.getElement('p.statusError');

         // Add the autocompleter.
         const exampleText = _js('Example: 2001-2005 Honda Civic');
         let catCard = new CategoryCard(areaInput, exampleText);
         let deviceText = areaInput.get('value').trim();

         if (deviceText) {
            catCard.updateDeviceInfo(deviceText);
            catCard.toggleDeviceBlurb(true);
         }

         DeviceFinder.initialize(areaInput, {
            onHide: function () {
               catCard.updateDeviceInfo(this.element.value);
            },
            onSelection: function () {
               catCard.toggleDeviceBlurb(true);
            },
         });

         let submit = function (ev) {
            ev.stop();
            let newParentTitle = areaInput.get('value');
            new Request.AjaxIO('wikiHasVariants', {
               onSuccess: function (response) {
                  if (response.hasVariant) {
                     let warningMsg = App.topicInUseWarning.replace(
                        App.topicInUseNameKey,
                        newParentTitle
                     );

                     if (!confirm(warningMsg)) {
                        return;
                     }
                  }

                  new Request.AjaxIO('saveWikiArea', {
                     onSuccess: function (response) {
                        if (!response.error) {
                           window.location.reload();
                        } else {
                           areaInput.blur();
                           msg.set('html', response.message);
                           msg.show().setStyle('opacity', 1);
                           (function () {
                              new Fx.Tween(msg).start('opacity', 0).chain(msg.hide.bind(msg));
                           }.delay(3000));
                        }
                     },
                  }).send(wikiid, newParentTitle);
               },
            }).send(newParentTitle);
         };

         form.addEvent('submit', submit);
         form.getElement('a').addEvent('click', submit);
      });
   },
});
