import createLinksModifier from 'Shared/Affiliates/create_links_modifier';

if (App.affiliateConfig) {
   const linksModifier = createLinksModifier(App.affiliateConfig);

   Object.keys(linksModifier).forEach(affiliate => {
      const links = document.querySelectorAll(`#wpBody a[href*="${affiliate}"]`);

      links.forEach(link => {
         link.href = linksModifier[affiliate](link.href);
      });
   });
}
