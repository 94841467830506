import AjaxIO from 'Shared/utils';

export class CategoryCard {
   private deviceField: Element;
   private device: Element;
   private image: Element;
   private title: Element;
   private displayTitle: Element;
   private summary: Element;

   constructor(deviceField: Element, exampleText: string = '') {
      this.deviceField = deviceField;
      this.device = document.getElementsByClassName('chosen-device')[0];
      this.image = document.getElementsByClassName('device-image')[0].children[0];
      this.title = document.getElementsByClassName('device-title')[0];
      this.displayTitle = document.getElementsByClassName('device-display-title')[0];
      this.summary = document.getElementsByClassName('device-summary')[0];

      this.deviceField?.addEventListener(
         'blur',
         function () {
            const current = this.deviceField.get('value').trim();
            if (current && current !== exampleText) {
               this.toggleDeviceBlurb(true);
            }
         }.bind(this)
      );

      document.getElementsByClassName('change-button')[0].addEventListener(
         'click',
         function () {
            this.toggleDeviceBlurb(false);
            this.deviceField.focus();
         }.bind(this)
      );
   }

   public toggleDeviceBlurb(show: boolean) {
      if (show) {
         this.deviceField.classList.add('hidden');
         this.device.classList.remove('hidden');
      } else {
         this.deviceField.classList.remove('hidden');
         this.device.classList.add('hidden');
      }
   }

   public updateDeviceInfo(deviceTitle: string) {
      this.image.setAttribute('src', '');
      this.title.textContent = deviceTitle;
      this.displayTitle.textContent = '';
      this.summary.textContent = '';

      new AjaxIO('getDeviceInfo', {
         onSuccess: function (data) {
            this.image.setAttribute('src', data.image);
            this.title.textContent = data.title ? data.title : '';
            this.displayTitle.textContent = data.display_title ? data.display_title : '';
            this.summary.innerHTML = data.summaryHTML;
         }.bind(this),
      }).send(encodeURIComponent(deviceTitle));
   }
}
