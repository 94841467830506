onDomReady(function () {
   Dropdowns.initialize();
});

// eslint-disable-next-line no-var
export var Dropdowns = (window.Dropdowns = {
   dropdownToggleClass: '.dropdown-toggle',
   dropdownClass: '.dropdown',

   initialize: function () {
      let toggles = $$(this.dropdownToggleClass);

      if (toggles.length > 0) {
         $$('html').addEvent('click', function (ev) {
            let targetParent = ev.target.getParent();

            toggles.getParent().each(function (parent) {
               if (parent != targetParent && parent.hasClass('open')) {
                  parent.removeClass('open');
               }
            });
         });

         toggles.addEvent('click', function (ev) {
            ev.preventDefault();
            let parent = ev.target.getParent();

            parent.toggleClass('open');
            ev.target.focus();
         });
      }
   },
});
