import { Utils } from 'Shared/utils';
import { Button } from 'Shared/button-toggle';
import { FrameModules } from 'Shared/frame_modules';

FrameModules.add('GuideWorkLogSettingsFrameModule', function () {
   new GuideWorkLogSettings();
});

// eslint-disable-next-line no-var
export var GuideWorkLogSettings = (window.GuideWorkLogSettings = new Class({
   ajaxSpinner: new Element('img', {
      src: window.shared_constants.BaseURI('SITE_IMAGE_AJAX_LOAD'),
   }),

   initialize: function () {
      let self = this;
      this.form = document.id('sidebarGuideWorkLogSettings');
      this.guideid = this.form.getProperty('data-guideid');

      this.workLogToggle = new Button.Toggle('workLogToggle', {
         callback: this.toggle.bind(this),
      });
   },

   buildAjaxSpinner: function (elClass) {
      let element = new Element('span', {
         class: 'ajax-spinner ' + elClass,
      });

      return element.adopt(this.ajaxSpinner);
   },

   toggle: function (ev) {
      let el = ev.target,
         self = this,
         enabled = el.get('data-value') == 'enabled',
         revisionEl = $$('[name=intro[revisionid]]').pick(),
         revisionid = revisionEl === null ? App.guide_revisionid : revisionEl.get('value');

      $('workLogSettingsField').adopt(self.buildAjaxSpinner('workLogEnable'));

      new Request.AjaxIO('setWorkLogEnabled', {
         onSuccess: function (response) {
            $$('.ajax-spinner').dispose();

            if (response.error) {
               let msg = Utils.createAlert('error', response.error);
               $('guideWorkLogSettingsErrors').grab(msg, 'top');
               self.workLogToggle.untoggle();
               return;
            }

            if (revisionEl !== null) {
               revisionEl.set('value', response.revisionid);
            }

            App.guide_revisionid = response.revisionid;
            if (App.guideApi) {
               App.guideApi.revisionid = App.guide_revisionid;
            }

            $$('.form-builder').toggle(response.workLogEnabled);
         },
      }).send(self.guideid, revisionid, enabled);
   },
}));
