// eslint-disable-next-line no-var
export var Button = (window.Button = {});

Button.Toggle = new Class({
   Implements: [Events, Options],

   options: {
      activeClass: 'active',
      defaultText: 'Loading...',
      dataType: 'value',
      childType: 'button',
      event: 'click',
      callback: '',
   },

   initialize: function (container, options) {
      this.container = document.id(container);
      this.setOptions(options);

      this.buttons = this.container.getChildren(this.options.childType);

      this.buttons.each(
         function (el) {
            el.addEvent(this.options.event, this.toggle.bind(this));
         }.bind(this)
      );
   },

   toggle: function (ev) {
      ev.stop();
      let opts = this.options,
         el = ev.target.get('tag') !== opts.childType ? ev.target.getParent() : ev.target,
         value = el.get('data-' + opts.dataType);

      if (el.hasClass(opts.activeClass)) {
         return;
      } else {
         this.setActive(el, opts.activeClass);
      }

      if (typeof opts.callback == typeof Function) {
         opts.callback(ev);
      }
   },

   untoggle: function () {
      this.setActive(this.prevToggle, this.options.activeClass);
   },

   setActive: function (el, activeClass) {
      el.getSiblings().each(
         function (sib) {
            if (sib.hasClass(activeClass)) {
               this.prevToggle = sib;
               sib.removeClass(activeClass);
            }
         }.bind(this)
      );

      el.addClass(activeClass);
   },
});
