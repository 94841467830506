import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as Actions from '../actions';
import BannerContainer from './components/banner_container';
import DismissButton from './components/dismiss_button';
import { withBreakpoints } from 'core-components';

const LinkContainer = styled.a`
   flex-basis: 100%;
`;

const TextContainer = styled.div`
   display: flex;
   flex: 1;
   justify-content: center;
   height: 100%;
`;

const Text = withBreakpoints(styled.h3`
   flex-basis: 95%;
   text-align: ${props => (props.breakpoints.isMobile() ? 'left' : 'center')};
   font-size: 16px;
   font-weight: 400;
   margin: 0;
   line-height: 25px;
   color: ${props => props.color};
`);

const mapDispatchToProps = dispatch => ({
   popBanner: id => dispatch(Actions.popBanner(id)),
});

const SmallBanner = ({ bannerData, index, numBanners, popBanner }) => {
   const { id, url, description, theme } = bannerData;
   const { textColor, background } = theme;
   const isHidden = index < numBanners - 1;

   let textContainer = (
      <TextContainer>
         <Text color={textColor}>{description}</Text>
      </TextContainer>
   );

   if (url) {
      textContainer = <LinkContainer href={url}>{textContainer}</LinkContainer>;
   }

   return (
      <BannerContainer
         onClick={() => popBanner(id)}
         index={index}
         isHidden={isHidden}
         background={background}
      >
         {textContainer}
         <DismissButton color={textColor} onClick={() => popBanner(id)} hideTextOnMobile={true} />
      </BannerContainer>
   );
};

export default connect(null, mapDispatchToProps)(SmallBanner);
